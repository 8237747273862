import { PageNumberTitle, PaymentTemplate, Anchor } from "components";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { ReactComponent as Icon } from "assets/images/icon-info-green.svg";
import "./PaymentSubmitted.css";

export default function PaymentSubmitted() {
  const [cookies] = useCookies(["id_token"]);
  const [counter, setCounter] = useState(10);
  const redirectUrl = sessionStorage.getItem("redirect_url");
  const bodyDescriptions = {
    newbo: {
      first:
        "You will shortly receive an email containing your contract, which you will need to sign before we can begin processing your payment.",
      second: "You may also be asked to provide additional documentation to support your account setup.",
    },
    exbo: {
      first: "Your payment has now been submitted and is being processed.",
      second:
        "Payments can take between 1-2 working days to be processed. For further updates, please log in to your FlexiPay account, where you can see the payment status.",
    },
  };

  useEffect(() => {
    if (redirectUrl) {
      setTimeout(() => {
        if (counter > 0) {
          setCounter((count) => count - 1);
        }

        if (counter === 1) {
          window.location.replace(redirectUrl);
        }
      }, 1000);
    }
  }, [counter, setCounter, redirectUrl]);

  function renderBodyDescriptions() {
    const descriptionKey = cookies.id_token ? "exbo" : "newbo";

    return (
      <div className="payment-submitted-body">
        <div>{bodyDescriptions[descriptionKey]["first"]}</div>
        <br />
        <div>{bodyDescriptions[descriptionKey]["second"]}</div>
      </div>
    );
  }

  function renderRedirectionBanner() {
    if (redirectUrl) {
      return (
        <div className="payment-submitted-body">
          <div className="payment-submitted-body-redirect">
            This page will automatically redirect you in <span className="font-roboto--bold">{counter} seconds</span>
          </div>
          <Anchor text="Close" href={redirectUrl} additionalClassNames={"btn btn--secondary"} />
        </div>
      );
    }
  }

  return (
    <PaymentTemplate backgroundClass="confetti">
      <PageNumberTitle title="Payment request submitted" />
      <div className="payment-submitted-container">
        <div className="payment-submitted-sub-container">
          <div className="payment-submitted-icon-panel">
            <Icon width="20" height="20" viewBox="0 0 20 20" />
          </div>
          <div>
            <div className="payment-submitted-title">What happens now?</div>
            {renderBodyDescriptions()}
            {renderRedirectionBanner()}
          </div>
        </div>
      </div>
    </PaymentTemplate>
  );
}
