import React, { useCallback } from "react";
import { API } from "utils/api";
import "./AddressResultsItem.css";

export default function AddressResultItem({ item, onItemSelected, updateResults, triggerLoading }) {
  const handleItemSelected = () => {
    triggerLoading(true);
    if (item.type === "address") {
      fetchAddressDetails(item.id);
    } else {
      fetchAddressContainerSearch(item.id);
    }
  };

  const fetchAddressContainerSearch = useCallback(
    async (value) => {
      const result = await API.addressSearch("container", value);
      if (result.isSuccess) {
        updateResults(result.payload);
      }
      triggerLoading(false);
    },
    [updateResults, triggerLoading]
  );

  const fetchAddressDetails = useCallback(
    async (id) => {
      const result = await API.address(id);
      if (result.isSuccess) {
        onItemSelected(item.title, result.payload);
      }
      triggerLoading(false);
    },
    [triggerLoading, item, onItemSelected]
  );

  return (
    <li className="address-results-item" onClick={handleItemSelected}>
      <div className="address-results-item-title">{item.title}</div>
      <div className="address-results-item-description">{item.subtitle}</div>
      <div className="address-results-item-divider" />
    </li>
  );
}
