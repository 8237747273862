import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CompanySearch, DynamicLabelInput, PaymentTemplate, Loading, PageNumberTitle, StickyButton } from "components";
import { PAGES } from "utils/config";
import { REGEX } from "utils/regex";
import { API } from "utils/api";
import AppState from "state";
import "./Eligibility.css";
import { handleError } from "utils/handleError";

export default function Eligibility() {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const navigate = useNavigate();

  const isSubmitButtonEnabled = () => {
    const validEmail = email.match(REGEX.email);
    const validPhoneNumber = phoneNumber.match(REGEX.phone);
    return validEmail && validPhoneNumber && !!Object.keys(selectedCompany).length;
  };

  const selectedCompanyCallback = (company) => {
    setSelectedCompany(company);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const eligibility_submitted_details = {
      company_name: selectedCompany.company_name,
      companies_house_id: selectedCompany.company_number,
      company_codas_id: selectedCompany.company_codas_id,
      email: email,
      phone_number: `+44${phoneNumber}`
    };
    const result = await API.postEligibility({
      payment_link_uuid: AppState.get("PaymentLink")["payment_link_uuid"],
      eligibility_submitted_details: eligibility_submitted_details
    });

    if (result.isSuccess) {
      AppState.updateLoanApplication("loan_application_uuid", result.payload["loan_application_uuid"]);
      AppState.updateLoanApplication("email", eligibility_submitted_details["email"]);
      AppState.updateLoanApplication("phone_number", eligibility_submitted_details["phone_number"]);
      navigate(PAGES.eligibilityPolling);
    } else {
      handleError(result, navigate);
    }
    setIsLoading(false);
  };

  return (
    <PaymentTemplate>
      <PageNumberTitle title="Enter your details" number={1} />
      {isLoading ? (
        <div className="eligibility-loading-container">
          <Loading />
        </div>
      ) : (
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="eligibility-form-fields-container">
            <CompanySearch selectedCompanyCallback={selectedCompanyCallback} />
            <DynamicLabelInput
              id="email"
              text="Email address"
              type="email"
              value={email}
              regexRules={REGEX.email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <DynamicLabelInput
              text="Phone number"
              id="phone-number"
              sideLabel="+44"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              maxLength={10}
              value={phoneNumber}
              regexRules={REGEX.phone}
            />
          </div>
          <StickyButton disabled={!isSubmitButtonEnabled()} />
        </form>
      )}
    </PaymentTemplate>
  );
}
