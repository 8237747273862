import { PAGES } from "utils/config";

export const handleError = (APIResponse, navigate, onServerErrorNavigateTo = null) => {
  if (APIResponse.isServerError || APIResponse.status === 424) {
    window.alert("We are unable to process your request at the moment, please try again later.");
    if (onServerErrorNavigateTo) navigate(onServerErrorNavigateTo);
    return;
  }

  const { title, detail } = APIResponse.payload ?? {};
  if (title === "Existing FlexiPay customer") {
    navigate(PAGES.loginRequired);
    return;
  }

  if (title && detail) {
    navigate(PAGES.errorPage, { state: { errorTitle: title, errorMessage: detail } });
    return;
  }

  navigate(PAGES.errorPage);
};
