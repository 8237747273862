import "./NoCompanyFound.css";

export default function NoCompanyFound({ value }) {
  return (
    <div>
      <div className="no-company-found-title">{`We couldn't find ${value}...`}</div>
      <div className="no-company-found-description">
        We search Companies House for limited companies only. Make sure you spell your company name correctly or search
        for the registration number
      </div>
    </div>
  );
}
