import React from 'react';
import './Radio.css';

const Radio = ({
  children,
  name,
  id,
  value,
  isChecked,
  onChange
}) => (
  <div className='radio-container'>
    <input
      className='radio'
      type="radio"
      name={name}
      id={id}
      value={value}
      checked={isChecked}
      onChange={onChange}
    />
    <label className='label' htmlFor={id}>
      {children}
    </label>
  </div>
);

export default Radio;
