import { API } from "utils/api";
import NoCompanyFound from "./NoCompanyFound/NoCompanyFound";
import CompanySearchLoading from "./CompanySearchLoading/CompanySearchLoading";
import DropdownSearchField from "../DropdownSearchField/DropdownSearchField";
import CompanyResultRow from "./CompanyResultsItem/CompanyResultsItem";

export default function CompanySearch({ selectedCompanyCallback }) {
  return (
    <DropdownSearchField
      id="company-information"
      placeholderText="Company name / number"
      selectedItemCallback={selectedCompanyCallback}
      searchFunction={(text) => API.searchCompanies(text)}
      renderNotFound={NoCompanyFound}
      renderLoading={CompanySearchLoading}
      renderResultsRow={CompanyResultRow}
    />
  );
}
