import { Loading, PaymentTemplate, PageNumberTitle } from "components";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "utils/api";
import AppState from "state";
import { PAGES } from "utils/config";
import "./EligibilityPolling.css";
import { handleError } from "utils/handleError";

export default function EligibilityPolling() {
  const loanApplicationUuid = AppState.get("LoanApplication")["loan_application_uuid"];
  const isRefreshing = useRef(false);
  const navigate = useNavigate();

  const handleAction = useCallback(
    (action) => {
      if (action === "line_of_credit_already_created") {
        navigate(PAGES.loginRequired);
      } else {
        navigate(PAGES.rejection);
      }
    },
    [navigate]
  );

  const refreshEligibility = useCallback(
    async (loanApplicationUuid) => {
      if (isRefreshing.current) {
        return;
      }

      isRefreshing.current = true;
      const result = await API.getEligibility(loanApplicationUuid);
      if (result.isSuccess) {
        const eligibility = result.payload;
        AppState.updateEligibility(eligibility);

        if (eligibility["status"] === "passed") navigate(PAGES.directors);
        else if (eligibility["status"] === "rejected") navigate(PAGES.rejection);
        else if (eligibility["status"] === "action_required") handleAction(eligibility["action"]);

        isRefreshing.current = false;
      } else {
        handleError(result, navigate, PAGES.eligibility);
      }
    },
    [navigate, handleAction]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refreshEligibility(loanApplicationUuid);
    }, 2000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      isRefreshing.current = false;
      window.alert("We are unable to process your request at the moment, please try again later.");
      navigate(PAGES.eligibility);
    }, 2 * 60 * 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [refreshEligibility, loanApplicationUuid, navigate]);

  return (
    <PaymentTemplate>
      <PageNumberTitle title="We are processing your details" number={1} />

      <label className="eligibility-polling-sub-title">
        This could take up to 2 minutes. Please do not exit this page.
      </label>
      <div className="eligibility-polling-container">
        <Loading />
      </div>
    </PaymentTemplate>
  );
}
