import { PaymentTemplate, PageNumberTitle, IconInfoText } from "components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PAGES } from "utils/config";
import ShareholderModal from "./ShareholderModal/ShareholderModal";
import ShareholderCheckbox from "./ShareholderCheckbox/ShareholderCheckbox";
import { CONTACT_US_URL } from "utils/config";
import AppState from "state";
import "./Shareholders.css";

export default function Shareholders() {
  const navigate = useNavigate();
  const [shareholders, setShareholders] = useState(() => {
    const eligibility = AppState.get("Eligibility");
    const fetchedShareholders = eligibility !== undefined ? eligibility["ebo_list"] : [];
    return fetchedShareholders.map((item, index) => {
      item["index"] = index;
      item["checked"] = false;
      item["dob"] = {};
      item["addresses"] = [];
      return item;
    });
  });
  const [selectedShareholder, setSelectedShareholder] = useState({});
  const [showShareholderDetailsModal, setShowShareholderDetailsModal] = useState(false);

  const addDetailsPressed = (shareholderIndex) => {
    const shareholder = shareholders[shareholderIndex];
    setSelectedShareholder(shareholder);
    setShowShareholderDetailsModal(true);
  };

  const updateShareholdersList = (selectedIndex, dob, addresses, moreThan3Years) => {
    const updatedList = shareholders.map((shareholder, index) => {
      if (selectedIndex === index) {
        return { ...shareholder, checked: moreThan3Years, dob: dob, addresses: addresses };
      }
      return shareholder;
    });
    setShareholders(updatedList);
  };

  const validSharesHeld = () => {
    const totalPercentHeld = shareholders
      .filter((item) => item.checked)
      .reduce((total, item) => total + item.percent_shares_held, 0);
    return totalPercentHeld >= 50;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    AppState.updateLoanApplication("ebo_list", shareholders);
    navigate(PAGES.businessDetails);
  };

  useEffect(() => {
    if (shareholders.length === 0) {
      navigate(PAGES.errorPage);
    }
  }, [shareholders, navigate]);

  const shareholderCheckboxes = shareholders.map((shareholder, index) => {
    return (
      <div key={index}>
        <ShareholderCheckbox shareholder={shareholder} handleAddDetails={addDetailsPressed} />
        <hr className="faint-divider" />
      </div>
    );
  });

  return (
    <PaymentTemplate>
      <PageNumberTitle title="Add shareholder information" number={3} />
      <div className="shareholders-content">
        <label className="shareholders-sub-title">
          We require date of birth &amp; address history for shareholders covering 50%+ of business ownership.
        </label>
        <div className="shareholder-checkbox-container">
          {shareholderCheckboxes}
          <IconInfoText text="Can't see yourself listed here?" linkText="Contact us here" linkUrl={CONTACT_US_URL} />
        </div>
        <div className="shareholders-cta">
          <input
            className="btn btn--primary"
            id="Continue"
            type="submit"
            value="Continue"
            disabled={!validSharesHeld()}
            onClick={handleSubmit}
          />
        </div>

        {showShareholderDetailsModal && (
          <ShareholderModal
            shareholder={selectedShareholder}
            handleClose={() => setShowShareholderDetailsModal(false)}
            handleShareholderDetailsSubmitted={updateShareholdersList}
          />
        )}
      </div>
    </PaymentTemplate>
  );
}
