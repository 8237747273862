import { useState } from "react";
import { Loading, PaymentTemplate, PageNumberTitle, IconInfoText, CurrencyInput } from "components";
import { RadioGroup } from "components";
import "./BusinessDetails.css";
import AppState from "state";
import { PAGES } from "utils/config";
import { API } from "utils/api";
import { LoanApplicationBuilder } from "./LoanApplicationBuilder";
import { useNavigate } from "react-router-dom";
import { handleError } from "utils/handleError";

export default function BusinessDetails() {
  const navigate = useNavigate();
  const [businessDetails, setBusinessDetails] = useState({
    turnover: null,
    profitLossAmount: null,
    debt: null,
    overdraft: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  async function callPostLoanApplication() {
    setIsLoading(true);
    const loanApplicationUuid = AppState.get("LoanApplication")["loan_application_uuid"];
    const loanApplicationDetails = LoanApplicationBuilder.build();
    const result = await API.postLoanApplication(loanApplicationUuid, loanApplicationDetails);
    if (result.isSuccess) {
      navigate(PAGES.loanApplicationPolling);
    } else {
      handleError(result, navigate);
    }
    setIsLoading(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    AppState.updateLoanApplication("business_info", businessDetails);
    callPostLoanApplication();
  };

  const lossRadios = (
    <RadioGroup
      name="profit-loss-radio"
      additionalClassnames="radio-loss"
      items={[
        {
          label: "£0 - £50k",
          value: -25000,
          id: "loss-25000",
        },
        {
          label: "£50k - £100k",
          value: -75000,
          id: "loss-75000",
        },
        {
          label: "£100k+",
          value: -200000,
          id: "loss-200000",
        },
      ]}
      onChange={(e) => {
        setBusinessDetails({ ...businessDetails, profitLossAmount: Number(e.target.value) });
      }}
      selectedValue={businessDetails.profitLossAmount}
    />
  );

  const profitRadios = (
    <RadioGroup
      name="profit-loss-radio-group"
      additionalClassnames="radio-profit"
      items={[
        {
          label: "£0 - £50k",
          value: 25000,
          id: "profit-25000",
        },
        {
          label: "£50k - £100k",
          value: 75000,
          id: "profit-75000",
        },
        {
          label: "£100k - £1M",
          value: 550000,
          id: "profit-550000",
        },
        {
          label: "£1M+",
          value: 2000000,
          id: "profit-2000000",
        },
      ]}
      onChange={(e) => setBusinessDetails({ ...businessDetails, profitLossAmount: Number(e.target.value) })}
      selectedValue={businessDetails.profitLossAmount}
    />
  );

  const debtRadios = (
    <RadioGroup
      name="debt-radio-group"
      items={[
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ]}
      onChange={(e) => setBusinessDetails({ ...businessDetails, debt: e.target.value })}
      selectedValue={businessDetails.debt}
    />
  );

  const disableButton = () => {
    const { turnover, profitLossAmount, debt } = businessDetails;
    const valid = turnover > 0 && !!profitLossAmount & !!debt;
    return !valid;
  };

  function renderBody() {
    if (isLoading) {
      return (
        <div className="direct-debit-loading-container">
          <Loading />
        </div>
      );
    }

    return (
      <form id="director-form" onSubmit={handleSubmit}>
        <div className="business-details-content">
          <label>Please provide some financial details about your business</label>
          <CurrencyInput
            id="turnover"
            text="Turnover (Last Financial Year)"
            value={businessDetails.turnover}
            amountChangedCallback={(event) => {
              setBusinessDetails({
                ...businessDetails,
                turnover: Number(event.target.value),
              });
            }}
          />

          <div className="label-title font-roboto-normal">
            What was your approximate <span className="font-roboto--bold">profit</span> or{" "}
            <span className="font-roboto--bold">loss</span> for the last financial year?
          </div>
          <div className="business-details-horizontal-grouping">
            <div className="business-details-radio-vertical-containers radio-profit">
              <span className="radio-group-title font-roboto--bold">Profit</span>
              {profitRadios}
            </div>
            <div className="business-details-radio-vertical-containers radio-loss">
              <span className="radio-group-title font-roboto--bold">Loss</span>
              {lossRadios}
            </div>
          </div>
          <div className="label-title font-roboto-normal">
            Tell us about any <span className="font-roboto--bold">new debt</span> your{" "}
            <span className="font-roboto--bold">business</span> has taken out in the{" "}
            <span className="font-roboto--bold">last 12 months</span>
          </div>
          <div>
            <span className="radio-group-title font-roboto--bold">More than £25,000?</span>
            <div className="business-details-horizontal-grouping">{debtRadios}</div>
          </div>

          <CurrencyInput
            id="overdraft"
            value={businessDetails.overdraft}
            sideLabel="£"
            text="Business Bank Overdraft Limit"
            regexRules={/^[0-9]\d*$/}
            amountChangedCallback={(e) =>
              setBusinessDetails({
                ...businessDetails,
                overdraft: e.target.value === null ? null : Number(e.target.value),
              })
            }
          />

          <IconInfoText text="We need to run a quotation credit search on behalf of yourself and the business" />
          <input className="btn btn--primary" type="submit" value="Continue" disabled={disableButton()} />
        </div>
      </form>
    );
  }

  return (
    <PaymentTemplate>
      <PageNumberTitle title="Add business details" number={4} />
      {renderBody()}
    </PaymentTemplate>
  );
}
