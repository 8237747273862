import { Loading, PaymentTemplate, PageNumberTitle } from "components";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "utils/api";
import AppState from "state";
import { PAGES } from "utils/config";
import "./LoanApplicationPolling.css";
import { handleError } from "utils/handleError";

export default function LoanApplicationPolling() {
  const loanApplicationUuid = AppState.get("LoanApplication")["loan_application_uuid"];
  const isRefreshing = useRef(false);
  const navigate = useNavigate();

  const refreshLoanApplication = useCallback(
    async (loanApplicationUuid) => {
      if (isRefreshing.current) {
        return;
      }
      isRefreshing.current = true;

      const result = await API.getLoanApplicationStatus(loanApplicationUuid);
      if (result.isSuccess) {
        if (result.payload["status"] === "offered") {
          AppState.updateLoanApplication("credit_limit", result.payload["credit_limit"]["value"]);
          navigate(PAGES.directDebit);
        } else if (result.payload["status"] === "rejected") {
          navigate(PAGES.rejection);
        }
      } else {
        handleError(result, navigate, PAGES.businessDetails);
      }
      isRefreshing.current = false;
    },
    [navigate]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refreshLoanApplication(loanApplicationUuid);
    }, 2000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      isRefreshing.current = false;
      window.alert("We are unable to process your request at the moment, please try again later.");
      navigate(PAGES.businessDetails);
    }, 2 * 60 * 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [refreshLoanApplication, loanApplicationUuid, navigate]);

  return (
    <PaymentTemplate>
      <PageNumberTitle title="We are processing your details" number={4} />

      <label className="loan-application-polling-sub-title">
        This could take up to 2 minutes. Please do not exit this page.
      </label>
      <div className="loan-application-polling-container">
        <Loading />
      </div>
    </PaymentTemplate>
  );
}
