import { baseUrl, flexipayLiteConnectorBaseUrl, flexipayOriginationsApiBaseUrl } from "./config";
import { Honeybadger } from "@honeybadger-io/react";

export class APIResponse {
  constructor(isSuccess = false, isError = false, isServerError = false, payload = {}, status = null) {
    this.isSuccess = isSuccess;
    this.isError = isError;
    this.isServerError = isServerError;
    this.payload = payload;
    this.status = status;
  }

  success(payload = {}, status) {
    this.isSuccess = true;
    this.payload = payload;
    this.status = status;
    return this;
  }

  error(payload = {}, status) {
    this.isError = true;
    this.payload = payload;
    this.status = status;
    return this;
  }

  serverError(payload) {
    this.isServerError = true;
    this.payload = payload;
    return this;
  }

  static async handleResponse(response) {
    let payload = {};
    try {
      payload = await response.json();
    } catch {}

    if (response.status >= 200 && response.status <= 399) {
      return new APIResponse().success(payload, response.status);
    } else {
      return new APIResponse().error(payload, response.status);
    }
  }
}

export class API {
  static async fetchWithRetry(url, { method, headers = { "Content-Type": "application/json" }, bodyJson = null }) {
    const fetch = require("fetch-retry")(global.fetch);
    const options = {
      method: method,
      body: bodyJson,
      headers: headers,
      retryDelay: 1000,
      retryOn: (attempt, error, response) => {
        if (response && response.status >= 500 && attempt < 5) return true;
      }
    };
    try {
      const response = await fetch(url, options);
      if (response.status >= 500) throw new Error(`Server error when calling: ${url}`);

      return APIResponse.handleResponse(response);
    } catch (error) {
      Honeybadger.notify(error);
      return new APIResponse().serverError(error);
    }
  }

  static async searchCompanies(searchString) {
    const url = `${flexipayOriginationsApiBaseUrl()}/company_search?query=${searchString}`;
    const response = await this.fetchWithRetry(url, { method: "GET" });
    return response;
  }

  static async addressSearch(type, searchString) {
    const url = `${flexipayOriginationsApiBaseUrl()}/address_search?type=${type}&query=${encodeURIComponent(
      searchString
    )}`;
    const response = await this.fetchWithRetry(url, { method: "GET" });
    return response;
  }

  static async address(searchString) {
    const url = `${flexipayOriginationsApiBaseUrl()}/address/${encodeURIComponent(searchString)}`;
    const response = await this.fetchWithRetry(url, { method: "GET" });
    return response;
  }

  static async postEligibility(eligibilityDetails) {
    const url = `${baseUrl()}/eligibility`;
    const response = await this.fetchWithRetry(url, { method: "POST", bodyJson: JSON.stringify(eligibilityDetails) });
    return response;
  }

  static async postFlexipayLiteEligibility(eligibilityDetails) {
    const url = `${flexipayLiteConnectorBaseUrl()}/eligibility`;
    const response = await this.fetchWithRetry(url, { method: "POST", bodyJson: JSON.stringify(eligibilityDetails) });
    return response;
  }

  static async getEligibility(loanApplicationUuid) {
    const url = `${flexipayOriginationsApiBaseUrl()}/eligibility/${loanApplicationUuid}`;
    const response = await this.fetchWithRetry(url, { method: "GET" });
    return response;
  }

  static async postLoanApplication(loanApplicationUuid, loanApplicationDetails) {
    const url = `${flexipayOriginationsApiBaseUrl()}/loan_application`;
    const response = await this.fetchWithRetry(url, {
      method: "POST",
      bodyJson: JSON.stringify({
        loan_application_uuid: loanApplicationUuid,
        loan_application_submitted_details: loanApplicationDetails
      })
    });
    return response;
  }

  static async postLineOfCredit(loanApplicationUuid, payeeDetails) {
    const url = `${flexipayOriginationsApiBaseUrl()}/line_of_credit`;
    const response = await this.fetchWithRetry(url, {
      method: "POST",
      bodyJson: JSON.stringify({ loan_application_uuid: loanApplicationUuid, payee_account: payeeDetails })
    });
    return response;
  }

  static async fetchPaymentLink(payment_link_uuid) {
    const url = `${baseUrl()}/payment_link/${payment_link_uuid}`;
    const response = await this.fetchWithRetry(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    });
    return response;
  }

  static async fetchPaymentDetails(payment_link_uuid, idToken) {
    const url = `${baseUrl()}/payment_link/${payment_link_uuid}/payment_details`;
    const response = await this.fetchWithRetry(url, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + idToken }
    });
    return response;
  }

  static async fetchPublicSupplierData(supplierId, amount) {
    const url = `${baseUrl()}/supplier/${supplierId}/public_data?amount=${amount}`;
    const response = await this.fetchWithRetry(url, { method: "GET" });
    return response;
  }

  static async postSupplierPaymentLink(supplier_id, amount, payeeReference) {
    const url = `${baseUrl()}/supplier/${supplier_id}/payment_link`;
    const response = await this.fetchWithRetry(url, {
      method: "POST",
      bodyJson: JSON.stringify({
        requested_amount: { currency: "GBP", value: amount },
        reference: payeeReference
      })
    });
    return response;
  }

  static async postDrawdown(payment_link_uuid, idToken) {
    const url = `${baseUrl()}/drawdown`;
    const response = await this.fetchWithRetry(url, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + idToken },
      bodyJson: JSON.stringify({ payment_link_uuid: payment_link_uuid })
    });
    return response;
  }

  static async getLoanApplicationStatus(loanApplicationUuid) {
    const url = `${flexipayOriginationsApiBaseUrl()}/loan_application_status/${loanApplicationUuid}`;
    const response = await this.fetchWithRetry(url, { method: "GET" });
    return response;
  }
}
