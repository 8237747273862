import { useState, useEffect } from "react";
import Confetti from 'react-confetti';

const CONFETTI_SCALE_FACTOR = 0.15;
const CONFETTI_PARTICLE_SIZE = 24;
const CONFETTI_COLORS = ['#aae8cb', '#d8f5e6', '#b7ecd3', '#c7f0dc'];
const CONFETTI_GRAVITY = 0.035;
const CONFETTI_PIECES = 80;
const CONFETTI_STYLE = {
    pointerEvents: 'none',
    zIndex: 5
  }

export default function ConfettiBanner() {
    const [confettiFinished, setConfettiFinished] = useState(false);
    const [runConfetti, setRunConfetti] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => setRunConfetti(false), 1000);
        return () => clearTimeout(timeout);
    }, []);

    const drawShape = (ctx) => {
        const scale = 1 - Math.random() * CONFETTI_SCALE_FACTOR;
        ctx.save();
        ctx.scale(scale, scale);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.arcTo(
          CONFETTI_PARTICLE_SIZE,
          0,
          CONFETTI_PARTICLE_SIZE,
          CONFETTI_PARTICLE_SIZE,
          CONFETTI_PARTICLE_SIZE / 2
        );
        ctx.lineTo(0, CONFETTI_PARTICLE_SIZE);
        ctx.closePath();
        ctx.fillStyle =
          CONFETTI_COLORS[Math.floor(Math.random() * CONFETTI_COLORS.length)];
        ctx.fill();
        ctx.restore();
      };
    
      const handleConfettiComplete = (confetti) => {
        if (confetti) {
          confetti.reset();
          setConfettiFinished(true);
        }
      };

      return (
        <>
          {!confettiFinished && <Confetti
                style={CONFETTI_STYLE}
                gravity={CONFETTI_GRAVITY}
                wind={0.01}
                numberOfPieces={runConfetti ? CONFETTI_PIECES : 0}
                recycle={runConfetti}
                onConfettiComplete={handleConfettiComplete}
                drawShape={drawShape}
              />}
        </>)
}