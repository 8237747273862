import "./Modal.css";

const Modal = ({ children }) => {
  return (
    <dialog className="dialog" open>
      <div className="modal">
        <section className="modal-container">{children}</section>
      </div>
    </dialog>
  );
};

export default Modal;
