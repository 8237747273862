import {
  monthsSelectBox,
  yearsSelectBox,
  convertMonthNameToMonthValue,
} from "pages/Onboarding/Shareholders/ShareholderModal/DOBSelector/DOBUtils";
import { useState, useEffect } from "react";
import { DynamicLabelInput, IconInfoText, DropdownSearchField } from "components";
import AddressResultItem from "./AddressResultsItem/AddressResultsItem";
import { API } from "utils/api";
import "./ShareholderAddress.css";

export default function ShareholderAddress({ selectedAddress, name, handleAddressSubmitted }) {
  const [shouldValidateFields, setShouldValidateFields] = useState(true);
  const [showAddressFields, setShowAddressFields] = useState(!!selectedAddress.postcode);
  const [address, setAddress] = useState(() => {
    return {
      index: selectedAddress.index,
      flat: selectedAddress.flat ?? "",
      houseNameNumber: selectedAddress.houseNameNumber ?? "",
      street: selectedAddress.street ?? "",
      city: selectedAddress.city ?? "",
      postcode: selectedAddress.postcode ?? "",
      month: selectedAddress.month,
      year: selectedAddress.year,
    };
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    handleAddressSubmitted(address);
  };

  const basicValidation = /^.{1,45}$/;
  const postcodeValidation = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i;

  const validAddresses = () => {
    const regex = new RegExp(postcodeValidation);
    const validPostcode = regex.test(address.postcode);

    return (
      address.houseNameNumber &&
      validPostcode &&
      address.street &&
      address.city &&
      address.month != null &&
      address.year
    );
  };

  const updateAddress = (event) => {
    const { name, value } = event.target;
    setAddress({ ...address, [name]: value });
  };

  const handleAddressSelected = (addressObject) => {
    setAddress({
      ...address,
      ...{
        city: addressObject.city ?? "",
        postcode: addressObject.postcode ?? "",
        flat: addressObject.flat_apartment_suite_or_other ?? "",
        street: addressObject.street ?? "",
        houseNameNumber: addressObject.house_number_or_name ?? "",
      },
    });
    setShowAddressFields(true);
    setShouldValidateFields(true);
  };

  const validateFields = () => {
    const elements = Array.from(document.getElementById("address-container").elements);
    elements.forEach((element) => {
      if (element.id !== "address-information") {
        element.focus();
      }
    });
  };

  useEffect(() => {
    if (shouldValidateFields) {
      validateFields();
      setShouldValidateFields(false);
    }
  }, [shouldValidateFields]);

  return (
    <div>
      <form id="address-container" className="form-container" onSubmit={handleSubmit}>
        <p className="label-title-dark font-roboto-normal">
          Add the current address for: <span className="font-roboto--bold">{name}:</span>
        </p>
        <div className="address-search-container">
          <DropdownSearchField
            id="address-information"
            placeholderText="Search street name or postcode"
            selectedItemCallback={handleAddressSelected}
            searchFunction={(text) => API.addressSearch("text", text)}
            renderResultsRow={AddressResultItem}
          />
          <div className="manual-button-container" hidden={showAddressFields}>
            <IconInfoText
              text="Can't find an address?"
              linkText="Enter manually"
              onClick={() => {
                setShowAddressFields(true);
              }}
            />
          </div>
        </div>

        {showAddressFields && (
          <div className="address-fields-container">
            <div>
              <div className="horizontal-container">
                <div className="max-width">
                  <DynamicLabelInput
                    id="flat"
                    text="Flat/Apartment/Suite *"
                    value={address.flat}
                    onChange={updateAddress}
                    infoMessage="* Leave blank if not applicable"
                  />
                </div>
                <div className="max-width">
                  <DynamicLabelInput
                    id="houseNameNumber"
                    text="House name or number"
                    value={address.houseNameNumber}
                    regexRules={basicValidation}
                    onChange={updateAddress}
                  />
                </div>
              </div>
            </div>

            <DynamicLabelInput
              id="street"
              text="Street"
              value={address.street}
              onChange={updateAddress}
              regexRules={basicValidation}
            />

            <div className="horizontal-container">
              <div className="max-width">
                <DynamicLabelInput
                  id="city"
                  text="City"
                  value={address.city}
                  regexRules={basicValidation}
                  onChange={updateAddress}
                />
              </div>
              <div className="max-width">
                <DynamicLabelInput
                  id="postcode"
                  regexRules={postcodeValidation}
                  text="Postcode"
                  value={address.postcode}
                  onChange={updateAddress}
                />
              </div>
            </div>
            <div className="move-date-container">
              <label className="label-title-dark font-roboto-normal" htmlFor="move-date">
                When did shareholder <span className="font-roboto--bold">{name}</span> move here? (approximately)
              </label>
              <div className="select-date-container">
                {monthsSelectBox(
                  (e) => setAddress({ ...address, month: convertMonthNameToMonthValue(e.value) }),
                  address.month,
                  "top"
                )}
                {yearsSelectBox((e) => setAddress({ ...address, year: e.value }), address.year, "top")}
              </div>
            </div>
          </div>
        )}
        <div className="button-container">
          <input
            className="btn btn--primary"
            type="submit"
            alt="Save address"
            id="Save"
            value="Save"
            disabled={!validAddresses()}
          />
        </div>
      </form>
    </div>
  );
}
