import { Modal } from "components";
import DOBSelector from "./DOBSelector/DOBSelector";
import ShareholderAddress from "./ShareholderAddress/ShareholderAddress";
import AddressHistory from "./AddressHistory/AddressHistory";
import { useState, useRef } from "react";
import "./ShareholderModal.css";

export default function ShareholderModal({ shareholder, handleClose, handleShareholderDetailsSubmitted }) {
  const state = {
    DOB: "DOB",
    AddressHistory: "AddressHistory",
    AddressDetail: "AddressDetail",
  };
  const [currentState, setCurrentState] = useState(state.DOB);
  const [dob, setDOB] = useState(shareholder.dob);
  const selectedAddress = useRef({});
  const addresses = useRef(shareholder.addresses);

  function livedMoreThan3Years(address) {
    const yearsLived = Math.floor((Date.now() - new Date(address.year, address.month)) / (365 * 24 * 60 * 60 * 1000));
    return yearsLived >= 3;
  }

  function checkIfAddressesHaveMoreThan3Years() {
    const valid = addresses.current.some((address) => {
      return livedMoreThan3Years(address);
    });
    return valid;
  }

  const handleDOBSubmitted = (day, month, year) => {
    setDOB({
      day: day,
      month: month,
      year: year,
    });

    // if only only address saved and is more than 3 years, then should never see history screen
    if (addresses.current.length === 1 && livedMoreThan3Years(addresses.current[0])) {
      const address = addresses.current[0];
      address["index"] = 0;
      selectedAddress.current = address;
      setCurrentState(state.AddressDetail);
      return;
    }

    const nextState = addresses.current.length > 0 ? state.AddressHistory : state.AddressDetail;
    setCurrentState(nextState);
  };

  const handleAddressSubmitted = (addressObject) => {
    let exists = false;
    const updatedList = addresses.current.map((address, index) => {
      if (addressObject.index === index) {
        exists = true;
        return { ...address, ...addressObject };
      }
      return address;
    });

    if (!exists) {
      updatedList.push(addressObject);
    }

    addresses.current = updatedList;
    const moreThan3Years = checkIfAddressesHaveMoreThan3Years();
    handleShareholderDetailsSubmitted(shareholder.index, dob, addresses.current, moreThan3Years);
    setCurrentState(state.AddressHistory);

    const autoCloseModal = addresses.current.length === 1 && livedMoreThan3Years(addresses.current[0]);
    if (autoCloseModal) {
      handleClose();
    }
  };

  const handleEditAddress = (selectedAddressIndex) => {
    const address = addresses.current[selectedAddressIndex];
    address.index = selectedAddressIndex;
    selectedAddress.current = address;
    setCurrentState(state.AddressDetail);
  };

  const handleAddNewAddress = () => {
    const address = { index: addresses.current.length };
    selectedAddress.current = address;
    setCurrentState(state.AddressDetail);
  };

  function renderBody() {
    switch (currentState) {
      case state.AddressDetail:
        return (
          <ShareholderAddress
            selectedAddress={selectedAddress.current}
            name={shareholder.title}
            handleAddressSubmitted={handleAddressSubmitted}
          />
        );
      case state.AddressHistory:
        return (
          <AddressHistory
            shareholderName={shareholder.title}
            shareholderAddresses={addresses.current}
            handleEditAddress={handleEditAddress}
            handleAddAddress={handleAddNewAddress}
          />
        );
      default:
        return <DOBSelector dob={dob} name={shareholder.title} handleDOBSubmitted={handleDOBSubmitted} />;
    }
  }

  return (
    <Modal>
      <div className="modal-container">
        <div className="display-flex">
          <div className="modal-navigation-text">Step {currentState === state.DOB ? 1 : 2} of 2</div>
          <button type="button" aria-label="modal-close-button" className="modal-close-button" onClick={handleClose} />
        </div>
        <div className="display-flex modal-header">
          <label className="modal-title font-amasis--normal">Shareholder Details</label>
        </div>
        <div className="modal-body-container">{renderBody()}</div>
      </div>
      <div className="modal-footer" hidden={currentState !== state.AddressHistory}>
        <input
          className="btn btn--primary"
          type="submit"
          alt="Save details"
          id="Close"
          value="Close"
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
}
