import React from "react";
import AppState from "state";
import { useState } from "react";
import { formatPrice } from "utils/priceHelpers";
import "./RepaymentPills.css";

function RepaymentPill({ index, amount, date, paid = false, centred, datesInLightMode = true }) {
  return (
    <div className={`repayment-pill ${centred ? "repayment-pill-centred" : ""}`}>
      <div className={`repayment-pill-body ${paid ? "repayment-pill-paid" : ""} `}>
        <span className="repayment-pill-index">{index + 1}</span>
        <span className="repayment-pill-amount">£{amount}</span>
      </div>
      <div className={`${datesInLightMode ? "repayment-pill-date" : "repayment-pill-date-dark"}`}>{date}</div>
    </div>
  );
}

export default function RepaymentPills({ firstInstalmentPaid = false, datesInLightMode = true, centred = false }) {
  const isFlexiLite = sessionStorage.getItem("FlexipayLiteApplication");
  const [paymentLink] = useState(AppState.get("PaymentLink"));
  const repaymentDates = formatRepaymentDates(paymentLink.payment_details.repayments, isFlexiLite, firstInstalmentPaid);

  return (
    <div className="repayment-pills">
      {paymentLink.payment_details.repayments.map((repayment, index) => {
        return (
          <RepaymentPill
            key={index}
            index={index}
            amount={formatPrice(repayment.amount.value)}
            date={repaymentDates[index]}
            paid={firstInstalmentPaid && index === 0}
            centred={centred}
            datesInLightMode={datesInLightMode}
          />
        );
      })}
    </div>
  );
}

const formatRepaymentDates = (repayments, isStartDateToday, firstInstalmentPaid) => {
  const dateFormat = { year: "2-digit", month: "short" };
  const startDate = new Date();
  let dates = Array.from(repayments, () => new Date(startDate));
  if (!isStartDateToday) {
    startDate.setMonth(startDate.getMonth() + 1);
  }
  dates.forEach((date, index) => {
    date.setMonth(startDate.getMonth() + index);
    date.toLocaleDateString("en-GB", dateFormat);
    dates[index] = String(date.toLocaleDateString("en-GB", dateFormat)).replace(/ /g, "’ ");
  });
  if (firstInstalmentPaid) {
    dates[0] = "Paid";
  } else if (isStartDateToday) {
    dates[0] = <span className="repayment-pill-date-bold">Today</span>;
  }
  return dates;
};
