import { PaymentTemplate, PageNumberTitle, Anchor, StickyButton } from "components";
import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ShareholderInput from "./ShareholderInput/ShareholderInput";
import AppState from "state";
import "./Shareholders.css";
import Loading from "components/Loading/Loading";
import { API } from "utils/api";
import { PAGES, COH_REGISTRY_URL } from "utils/config";
import { handleError } from "utils/handleError";
import { nthNumber } from "utils/numberHelpers";

export default function Shareholders() {
  const navigate = useNavigate();
  const flexiPayLiteApplication = useRef(AppState.get("FlexipayLiteApplication"));
  const paymentLink = useRef(AppState.get("PaymentLink"));
  const [isLoading, setIsLoading] = useState(false);
  const [shareholders, setShareholders] = useState([{ first_name: "", last_name: "", valid: false }]);
  const prevShareholders = useRef(shareholders);

  useEffect(() => {
    const scrollLastShareholderIntoView = () => {
      const lastShareholderInput = document.querySelector("li:last-child");
      if (lastShareholderInput && prevShareholders.current.length < shareholders.length && shareholders.length > 1) {
        lastShareholderInput.scrollIntoView({ behavior: "instant" });
      }
      prevShareholders.current = shareholders;
    };
    scrollLastShareholderIntoView();
  }, [shareholders, navigate]);

  const updateShareholders = useCallback(
    (shareholder, index, action) => {
      setShareholders((prevShareholders) => {
        let newShareholders = [...prevShareholders];
        switch (action) {
          case "add":
            newShareholders.push(shareholder);
            break;
          case "edit":
            newShareholders[index] = shareholder;
            break;
          case "remove":
            newShareholders.splice(index, 1);
            break;
          default:
            console.log("Invalid action");
        }
        return newShareholders;
      });
    },
    [setShareholders]
  );

  const shareholderInputList = shareholders.map((shareholder, index) => {
    return (
      <ShareholderInput
        // key forces the component to re-render when the shareholder list changes
        key={(index + 1).toString() + nthNumber(index + 1) + "_shareholder_details"}
        shareholder={shareholder}
        index={index}
        updateShareholders={updateShareholders}
        isLast={index === shareholders.length - 1}
      />
    );
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const { company_codas_id, companies_house_id, company_name, email, phone_number, first_name, last_name } =
      flexiPayLiteApplication.current;

    const director = {
      first_name: first_name,
      last_name: last_name
    };

    const eligibility_submitted_details = {
      companies_house_id: companies_house_id,
      company_codas_id: company_codas_id,
      company_name: company_name,
      email: email,
      phone_number: phone_number,
      first_name: first_name,
      last_name: last_name,
      shareholders: [director, ...shareholders.map(({ first_name, last_name }) => ({ first_name, last_name }))]
    };
    const result = await API.postFlexipayLiteEligibility({
      payment_link_uuid: paymentLink.current["payment_link_uuid"],
      eligibility_submitted_details: eligibility_submitted_details
    });

    if (!result.isSuccess) {
      handleError(result, navigate);
      return;
    }
    AppState.updateFlexipayLiteApplication("shareholders", eligibility_submitted_details["shareholders"]);
    AppState.updateFlexipayLiteApplication("payment_uuid", result.payload["payment_uuid"]);

    if (result.payload.stripe_client_secret) {
      AppState.updateFlexipayLiteApplication("stripe_client_secret", result.payload.stripe_client_secret);
      navigate(PAGES.flexipayLitePayment);
    } else {
      navigate(PAGES.rejection);
    }
    setIsLoading(false);
  };

  return (
    <PaymentTemplate paddingBottom={true}>
      <PageNumberTitle title="Persons of significant control" number={2} />
      {isLoading ? (
        <div className="eligibility-loading-container">
          <Loading />
        </div>
      ) : (
        <>
          <p className="shareholders-page-description">
            Plase enter the full names of any persons of significant control in your company as shown on the{" "}
            <Anchor text="Companies House register" href={COH_REGISTRY_URL} />.
          </p>
          <form className="shareholders-form" onSubmit={handleSubmit} autoComplete="off">
            <ul className="shareholders-input-list-container">{shareholderInputList}</ul>
            <StickyButton disabled={shareholders.some((shareholder) => shareholder.valid === false)} />
          </form>
        </>
      )}
    </PaymentTemplate>
  );
}
