import { ReactComponent as Icon } from "assets/images/info-icon.svg";
import "./IconInfoText.css";
import { Anchor } from "components";

export default function IconInfoText({ text, linkText, linkUrl, onClick }) {
  return (
    <div className="icon-info-text-container">
      <Icon width="20" height="20" viewBox="0 0 20 20" />
      <span className="icon-info-text">
        {text}
        {linkText && linkUrl && <Anchor text={linkText} href={linkUrl} additionalClassNames={"icon-info-text-link"} />}

        {linkText && onClick && (
          <button className="icon-info-text-button button-link" type="button" onClick={onClick}>
            {linkText}
          </button>
        )}
      </span>
    </div>
  );
}
