import AppState from "state";

export class LoanApplicationBuilder {
  static build() {
    const loanApplication = AppState.get("LoanApplication");

    // These are the same values that are sent in the GPO flexipay application
    // so need to make sure we send the same values
    // confluence document outlining the reasoning for these values
    // https://confluence.fundingcircle.com/display/FPAY/Remove+6+Company+Performance
    const otherDebtAmount = loanApplication["business_info"]["debt"] === "Yes" ? 25001 : 19999;

    return {
      ebo_list: loanApplication["ebo_list"]
        .filter((shareholder) => shareholder.checked)
        .map((shareholder) => this.buildShareholder(shareholder)),
      customer_info: {
        contact_name: {
          first_name: loanApplication["director_first_name"],
          last_name: loanApplication["director_last_name"],
        },
        email: loanApplication["email"],
        phone_number: loanApplication["phone_number"],
      },
      business_info: {
        company_type: "Limited Company",

        self_stated_turnover: { currency: "GBP", value: loanApplication["business_info"]["turnover"] },
        profit_loss_amount: { currency: "GBP", value: loanApplication["business_info"]["profitLossAmount"] },
        overdraft_maximum_available: { currency: "GBP", value: loanApplication["business_info"]["overdraft"] },
        other_debt_amount: { currency: "GBP", value: otherDebtAmount },
      },
    };
  }

  static buildShareholder(shareholder) {
    const addresses = shareholder.addresses.map((address) => {
      return {
        ...{
          house_number_or_name: address.houseNameNumber,
          street: address.street,
          city: address.city,
          postcode: address.postcode,
        },
        ...(address.flat && { abode_number: address.flat }),
      };
    });

    let result = {
      ebo_name: { first_name: shareholder.first_name, last_name: shareholder.last_name },
      dob: new Date(shareholder.dob.year, shareholder.dob.month, shareholder.dob.day, 1).toISOString().split("T")[0],
      percent_shares_held: shareholder.percent_shares_held,
      current_address: addresses.shift(),
    };

    if (addresses.length > 0) {
      result["previous_addresses"] = addresses;
    }

    return result;
  }
}
