import React, { useState, useRef, useEffect, useCallback } from "react";

import SearchInput from "./SearchInput/SearchInput";
import "./DropdownSearchField.css";
import { useDebounce } from "utils/debounce";

const defaultLoading = () => (
  <div className="dropdown-search-field-default-title">
    <p>Loading...</p>
  </div>
);

const defaultNotFound = () => (
  <div className="dropdown-search-field-default-title">
    <p>Not Found</p>
  </div>
);

export default function DropdownSearchField({
  id,
  selectedItemCallback,
  placeholderText,
  searchFunction,
  minCharactersToStartSearch = 3,
  renderResultsRow: ResultComponent,
  renderNotFound: NotFoundComponent = defaultNotFound,
  renderLoading: LoadingComponent = defaultLoading,
}) {
  const searchText = useRef("");
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isResultsOpen, setIsResultsOpen] = useState(false);
  const [results, setResults] = useState([]);
  const debouncedSearchText = useDebounce(value, 500);
  const [selectedItem, setSelectedItem] = useState({});

  const fetchResults = useCallback(
    async (text) => {
      setIsLoading(true);
      setIsResultsOpen(true);
      const result = await searchFunction(text);
      if (result.isSuccess) {
        setResults(result.payload);
      }
      setIsLoading(false);
    },
    [searchFunction]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (searchText.current.length >= minCharactersToStartSearch && Object.keys(selectedItem).length === 0) {
        setIsResultsOpen(false);
        await fetchResults(debouncedSearchText);
      } else {
        setResults([]);
      }
    };

    fetchData();
  }, [debouncedSearchText, selectedItem, fetchResults, minCharactersToStartSearch]);

  return (
    <div className="dropdown-search-field-container">
      <SearchInput
        id={id}
        value={value}
        readOnly={!!Object.keys(selectedItem).length}
        onChange={(event) => {
          const text = event.target.value;
          setValue(text);
          setIsResultsOpen(false);
          searchText.current = text;
        }}
        placeholderText={placeholderText}
        onClosePress={() => {
          setValue("");
          setIsResultsOpen(false);
          searchText.current = "";
          selectedItemCallback({});
          setSelectedItem({});
        }}
        selectedItem={selectedItem}
        isListResultOpen={isResultsOpen}
      />

      <ul className={`dropdown-search-field-row ${isResultsOpen ? "" : "dropdown-search-field-row-hidden"}`}>
        {isLoading && <LoadingComponent />}
        {!isLoading && (
          <>
            {(results?.length > 0 &&
              results.map((item, index) => (
                <ResultComponent
                  key={index}
                  item={item}
                  onItemSelected={(title, payload) => {
                    setIsResultsOpen(false);
                    setValue(title);
                    const selectedValue = payload || item;
                    selectedItemCallback(selectedValue);
                    setSelectedItem(selectedValue);
                  }}
                  updateResults={(results) => {
                    setResults(results);
                  }}
                  triggerLoading={(loading) => {
                    setIsLoading(loading);
                  }}
                />
              ))) || <NotFoundComponent value={value} />}
          </>
        )}
      </ul>
    </div>
  );
}
