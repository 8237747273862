import React from "react";
import "./CompanyResultsItem.css";

export default function CompanyResultItem({ item, onItemSelected }) {
  return (
    <li
      className="company-results-item"
      onClick={() => {
        onItemSelected(item.company_name);
      }}
    >
      <div className="company-results-item-title">{item.company_name}</div>
      <div className="company-results-item-description">
        {item.address} {item.postcode}
      </div>
      <div className="company-results-item-divider" />
    </li>
  );
}
