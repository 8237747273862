export const PAGES = {
  errorPage: "/error",
  signIn: "/sign-in",
  universalPaymentSplash: "/:supplierId",
  customPaymentSplash: "/:supplierId/:payment_link_uuid",
  eligibility: "/eligibility",
  eligibilityPolling: "/eligibility-polling",
  directors: "/directors",
  shareholders: "/shareholders",
  businessDetails: "/business-details",
  loanApplicationPolling: "/loan-application-polling",
  directDebit: "/direct-debit",
  paymentConfirmation: "/payment-confirmation",
  paymentSubmitted: "/payment-submitted",
  rejection: "/rejection",
  loginRequired: "/login-required",
  flexipayLiteEligibility: "/flexipay-lite-eligibility",
  flexipayLiteShareholders: "/flexipay-lite-shareholders",
  flexipayLitePaymentSubmitted: "/flexipay-lite-payment-submitted",
  flexipayLitePayment: "/flexipay-lite-payment"
};

export const COH_REGISTRY_URL = "https://find-and-update.company-information.service.gov.uk/";
export const CONTACT_US_URL = "https://support.fundingcircle.com/hc/en-us/requests/new";
export const FC_BORROWER_AGREEMENT_URL = "https://www.fundingcircle.com/uk/legal/borrower-agreement/";
export const FC_PRIVACY_POLICY_URL = "https://www.fundingcircle.com/uk/legal/privacy-policy/";
export const MODULR_URL =
  "https://www.fundingcircle.com/files/uk/information-packs/modulr-introduced-client-full-agreement-270323.pdf";
export const DIRECT_DEBIT_GUARANTEE_URL = "https://gocardless.com/direct-debit/guarantee/";

export function FLEXIPAY_AGREEMENT_URL(loanApplicationUuid) {
  return flexipayOriginationsApiBaseUrl() + "/credit_agreement_document/" + loanApplicationUuid;
}

export function baseUrl() {
  return sessionStorage.getItem("sandbox")
    ? process.env.REACT_APP_SANDBOX_BASE_URL
    : process.env.REACT_APP_LIVE_BASE_URL;
}

export function flexipayOriginationsApiBaseUrl() {
  return sessionStorage.getItem("sandbox")
    ? process.env.REACT_APP_SANDBOX_FLEXIPAY_ORIGINATIONS_API_BASE_URL
    : process.env.REACT_APP_LIVE_FLEXIPAY_ORIGINATIONS_API_BASE_URL;
}

export function flexipayLiteConnectorBaseUrl() {
  return sessionStorage.getItem("sandbox")
    ? process.env.REACT_APP_SANDBOX_FLEXIPAY_LITE_CONNECTOR_BASE_URL
    : process.env.REACT_APP_LIVE_FLEXIPAY_LITE_CONNECTOR_BASE_URL;
}

export const MIN_PAYMENT_LINK_AMOUNT = 100;
export const MAX_PAYMENT_LINK_AMOUNT = 20000;

export const FLEXILITE_TITLE = "Pay with FlexiPay";
export const FLEXIPAY_LINE_OF_CREDIT_TITLE = "Sign up for FlexiPay Line of Credit";
