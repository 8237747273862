import "./CompanySearchLoading.css";

export default function CompanySearchLoading() {
  return (
    <div className="company-search-loading-container">
      <div className="company-search-loading-title">Searching ...</div>
      <div className="company-search-loading-spinner" data-testid="loading-spinner" />
    </div>
  );
}
