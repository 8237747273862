import { ReactComponent as SuccessIcon } from "assets/images/success-icon.svg";
import { ReactComponent as FailIcon } from "assets/images/fail-icon.svg";

const Label = ({ text, htmlFor, error = undefined }) => {
  return (
    <div className="label-group-container">
      <label className={`label-title font-roboto-normal${error ? " text-color--red" : ""}`} htmlFor={htmlFor}>
        {text}
      </label>
      <div className="label-icon-container">
        {error === false && <SuccessIcon />}
        {error && <FailIcon />}
      </div>
    </div>
  );
};

export default Label;
