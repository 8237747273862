import { ReactComponent as Icon } from "assets/images/info-error-icon.svg";
import { PaymentTemplate, PageNumberTitle } from "components";
import "./Rejection.css";

export default function Rejection() {
  return (
    <PaymentTemplate hidePaymentSummary={true}>
      <PageNumberTitle title="Your business is not eligible for this service" />
      <div className="rejection-container">
        <div className="rejection-sub-container">
          <div className="rejection-icon-panel">
            <Icon className="rejection-error-icon" width="20" height="20" viewBox="0 0 20 20" />
          </div>
          <div>
            <div className="rejection-title">Sorry, we’re unable to make an offer</div>
            <div className="rejection-body">
              Thank you for applying for finance through Funding Circle, but unfortunately we can’t help you right now.
            </div>
          </div>
        </div>
      </div>
    </PaymentTemplate>
  );
}
