import React from 'react';

import './RadioGroup.css';
import Radio from './Radio';


const RadioGroup = ({
  legend,
  name,
  items,
  onChange,
  selectedValue,
  additionalClassnames
}) => {
  return (
    <fieldset
      className={`wrapper ${additionalClassnames}`}
      data-testid="radioGroup"
    >
      <legend className='legend'>{legend}</legend>
      <div className='radio-group'>
        {items.map((item, index) => (
          <Radio
            key={item.id || `${name}-${index}`}
            id={item.id || `${name}-${index}`}
            isChecked={selectedValue === item.value}
            name={name}
            onChange={onChange}
            value={item.value}
          >
            {item.label}
          </Radio>
        ))}
      </div>
    </fieldset>
  );
};

export default RadioGroup;
