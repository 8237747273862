import { useState } from "react";
import Logo from "components/Logo/Logo";
import { ReactComponent as ExpandIcon } from "assets/images/expand-icon.svg";
import { ReactComponent as CollapseIcon } from "assets/images/collapse-icon.svg";
import "./BaseTemplate.css";

export default function BaseTemplate({ expandText, mainContent, sideContent, ...rest }) {
  const [isExpanded, setIsExpanded] = useState(
    sideContent && sessionStorage.getItem("payment-summary-expanded") === "true"
  );
  const { backgroundClass } = rest;
  function toggleSideContent() {
    const newIsExpanded = !isExpanded;
    sessionStorage.setItem("payment-summary-expanded", newIsExpanded ? "true" : "false");
    setIsExpanded(newIsExpanded);
  }

  const switchLogoDisplay = (e) => {
    if (window.screen.width > 1200) return;
    const menuHeight = 48;
    const logoHeight = 80;
    const positionY = e.target.scrollTop;
    const logo = document.querySelector("header");
    const collapsibleContent = document.getElementById("collapsible-content");
    const mainContent = e.target;

    let contentHeight = `calc(100dvh - ${menuHeight + logoHeight}px)`;
    let display = "block";

    if (positionY > 0) {
      contentHeight = `calc(100dvh - ${menuHeight}px)`;
      display = "none";
    }

    logo.style.display = display;
    mainContent.style.height = contentHeight;
    collapsibleContent.style.height = contentHeight;
  };

  return (
    <div className="base-template">
      <header data-testid={"base-template-logo"}>
        <Logo />
      </header>
      {sideContent && (
        <div data-testid={"base-template-collapsible-menu"} className="base-template-collapsible-menu">
          <button className="base-template-collapse-button" onClick={() => toggleSideContent()}>
            <span>{expandText}</span>
            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
          </button>
          <div
            id="collapsible-content"
            className={`base-template-collapsible-content ${!isExpanded ? "base-template-side-content-hidden" : ""}`}
          >
            {sideContent}
          </div>
        </div>
      )}
      <div className={isExpanded ? "base-template-body base-template-body-hidden" : "base-template-body"}>
        <div
          data-testid={"base-template-main-content"}
          className={`base-template-main-content ${backgroundClass}`}
          onScroll={switchLogoDisplay}
        >
          {mainContent}
        </div>
        {sideContent && (
          <div data-testid={"base-template-side-content"} className="base-template-side-content">
            {sideContent}
          </div>
        )}
      </div>
    </div>
  );
}
