import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Loading, SplashTemplate, RepaymentTerms, DynamicLabelInput } from "components";
import { PAGES, FLEXILITE_TITLE, FLEXIPAY_LINE_OF_CREDIT_TITLE } from "utils/config";
import { API } from "utils/api";
import AppState from "state";
import "./CustomPaymentSplash.css";
import { configureHoneybadger } from "utils/configureHoneybadger";
import { ReactComponent as GroupIcon } from "assets/images/Group.svg";

export default function CustomPaymentSplash() {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentLink, setPaymentLink] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    if (searchParams.get("sandbox")) {
      sessionStorage.setItem("sandbox", searchParams.get("sandbox"));
      const sandboxkey = process.env.REACT_APP_HONEYBADGER_KEY_SANDBOX;
      configureHoneybadger(sandboxkey);
    }
  }, [searchParams]);

  const navigate = useNavigate();
  const { payment_link_uuid } = useParams();

  const fetchPaymentLink = useCallback(
    async (payment_link_uuid) => {
      setIsLoading(true);
      const result = await API.fetchPaymentLink(payment_link_uuid);
      if (result.isSuccess) {
        setPaymentLink(result.payload);
        AppState.updatePaymentLink(result.payload);
      } else {
        navigate(PAGES.errorPage, {
          state: {
            errorTitle: result.payload.title,
            errorMessage: result.payload.detail
          }
        });
      }
      setIsLoading(false);
    },
    [navigate]
  );

  useEffect(() => {
    payment_link_uuid ? fetchPaymentLink(payment_link_uuid) : navigate(PAGES.errorPage);
    sessionStorage.setItem("payment_link_uuid", payment_link_uuid);
  }, [fetchPaymentLink, payment_link_uuid, navigate]);

  // const handleSubmitExbo = (event) => {
  //   event.preventDefault();
  //   navigate(PAGES.paymentConfirmation);
  // };

  const handleSubmitNewBo = () => {
    navigate(PAGES.eligibility);
  };

  function showFlexiLite() {
    return paymentLink?.requested_amount.value <= 2000;
  }

  const submitButtonText = () => {
    if (showFlexiLite()) {
      return FLEXILITE_TITLE;
    } else {
      return FLEXIPAY_LINE_OF_CREDIT_TITLE;
    }
  };

  const submitButtonAction = (event) => {
    event.preventDefault();
    if (showFlexiLite()) {
      sessionStorage.setItem("FlexipayLiteApplication", "{}");
      navigate(PAGES.flexipayLiteEligibility);
    } else {
      return handleSubmitNewBo();
    }
  };

  function renderBody() {
    if (isLoading || !paymentLink) {
      return <Loading />;
    }

    return (
      <div className="custom-payment-splash">
        <div className="universal-payment-splash-group-icon">
          <GroupIcon />
        </div>
        <div className="custom-payment-splash-header">{paymentLink.payee_account_name}</div>
        {paymentLink && <div className="custom-payment-splash-subheading">{paymentLink.reference}</div>}
        {paymentLink && (
          <div className="custom-payment-splash-payment-amount">
            <DynamicLabelInput
              id="custom-payment-splash-amount"
              sideLabel="£"
              text="Amount to pay"
              value={`${Number(paymentLink.requested_amount.value).toFixed(2)}`}
              disabled={true}
            />
          </div>
        )}
        paymentLink
        <RepaymentTerms paymentDetails={paymentLink.payment_details} />
        <input
          className="custom-payment-newbo-button btn btn--primary"
          id="Continue"
          type="submit"
          value={submitButtonText()}
          onClick={submitButtonAction}
        />
        {/* <span>
          Already got FlexiPay?
          <input
            className="custom-payment-exbo-button button-link"
            type="submit"
            value="Sign in to make this payment"
            onClick={handleSubmitExbo}
          />
        </span> */}
      </div>
    );
  }

  return (
    <SplashTemplate
      showEligibilityDisclaimer={true}
      isFlexiLite={showFlexiLite()}
      supplierPaysFee={paymentLink?.payment_details.supplier_pays_fee}
      isLoading={isLoading}
    >
      {renderBody()}
    </SplashTemplate>
  );
}
