import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DynamicLabelInput,
  Loading,
  PageNumberTitle,
  CompanySearch,
  Anchor,
  StickyButton,
  RadioGroup
} from "components";
import { PaymentTemplate } from "components";
import { PAGES, COH_REGISTRY_URL } from "utils/config";
import { REGEX } from "utils/regex";
import { API } from "utils/api";
import AppState from "state";
import "./Eligibility.css";
import { handleError } from "utils/handleError";

export default function Eligibility() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [soleShareHolder, setSoleShareHolder] = useState("Yes");
  const nameValidations = /^[a-zA-Z]{3,20}$/;
  const navigate = useNavigate();

  const isSubmitButtonEnabled = () => {
    const validEmail = email.match(REGEX.email);
    const validPhoneNumber = phoneNumber.match(REGEX.phone);
    return firstName && lastName && validEmail && validPhoneNumber && !!Object.keys(selectedCompany).length;
  };

  const selectedCompanyCallback = (company) => {
    setSelectedCompany(company);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    function updateFlexipayLiteApplication(eligibility_submitted_details) {
      AppState.updateFlexipayLiteApplication("email", eligibility_submitted_details["email"]);
      AppState.updateFlexipayLiteApplication("phone_number", eligibility_submitted_details["phone_number"]);
      AppState.updateFlexipayLiteApplication("first_name", eligibility_submitted_details["first_name"]);
      AppState.updateFlexipayLiteApplication("last_name", eligibility_submitted_details["last_name"]);
      AppState.updateFlexipayLiteApplication("companies_house_id", eligibility_submitted_details["companies_house_id"]);
      AppState.updateFlexipayLiteApplication("company_codas_id", eligibility_submitted_details["company_codas_id"]);
      AppState.updateFlexipayLiteApplication("company_name", eligibility_submitted_details["company_name"]);
      if (soleShareHolder === "Yes") {
        AppState.updateFlexipayLiteApplication("shareholders", eligibility_submitted_details["shareholders"]);
      }
    }

    const eligibility_submitted_details = {
      companies_house_id: selectedCompany.company_number,
      company_name: selectedCompany.company_name,
      company_codas_id: selectedCompany.company_codas_id,
      email: email,
      phone_number: `+44${phoneNumber}`,
      first_name: firstName,
      last_name: lastName
    };

    if (soleShareHolder === "No") {
      updateFlexipayLiteApplication(eligibility_submitted_details);
      navigate(PAGES.flexipayLiteShareholders);
      return;
    }

    setIsLoading(true);
    eligibility_submitted_details["shareholders"] = [
      {
        first_name: firstName,
        last_name: lastName
      }
    ];
    const result = await API.postFlexipayLiteEligibility({
      payment_link_uuid: AppState.get("PaymentLink")["payment_link_uuid"],
      eligibility_submitted_details: eligibility_submitted_details
    });

    if (!result.isSuccess) {
      handleError(result, navigate);
      setIsLoading(false);
      return;
    }
    updateFlexipayLiteApplication(eligibility_submitted_details);
    AppState.updateFlexipayLiteApplication("payment_uuid", result.payload["payment_uuid"]);

    if (result.payload.stripe_client_secret) {
      AppState.updateFlexipayLiteApplication("stripe_client_secret", result.payload.stripe_client_secret);
      navigate(PAGES.flexipayLitePayment);
    } else {
      navigate(PAGES.rejection);
    }
    setIsLoading(false);
  };

  const renderSoleOwnerButtons = () => {
    return (
      <div className="eligibility-form-sole-proprietorship-field-selection">
        <div className="eligibility-form-sole-proprietorship-field-selection-yes">
          <RadioGroup
            name="sole-shareholder"
            onChange={(e) => {
              setSoleShareHolder(e.target.value);
            }}
            selectedValue={soleShareHolder}
            items={[
              {
                label: "Yes",
                value: "Yes"
              },
              {
                label: "No",
                value: "No"
              }
            ]}
          />
        </div>
      </div>
    );
  };

  return (
    <PaymentTemplate>
      <PageNumberTitle title="Enter your details" number={1} />
      {isLoading ? (
        <div className="eligibility-loading-container">
          <Loading />
        </div>
      ) : (
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="eligibility-form-fields-container">
            <CompanySearch selectedCompanyCallback={selectedCompanyCallback} />
            <div className="eligibility-form-fields-first-last-name">
              <DynamicLabelInput
                text="First Name"
                id="first-name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                value={firstName}
                regexRules={nameValidations}
              />
              <DynamicLabelInput
                text="Last Name"
                id="last-name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastName}
                regexRules={nameValidations}
              />
            </div>
            <DynamicLabelInput
              text="Phone number"
              id="phone-number"
              sideLabel="+44"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              maxLength={10}
              value={phoneNumber}
              regexRules={REGEX.phone}
            />
            <DynamicLabelInput
              id="email"
              text="Email address"
              type="email"
              value={email}
              regexRules={REGEX.email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="eligibility-form-sole-proprietorship-field">
              Are you the sole person of significant control in the company as shown on the{" "}
              <Anchor text="Companies House register" href={COH_REGISTRY_URL} />?{renderSoleOwnerButtons()}
            </div>
          </div>

          <StickyButton disabled={!isSubmitButtonEnabled()} />
        </form>
      )}
    </PaymentTemplate>
  );
}
