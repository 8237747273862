import { Honeybadger } from "@honeybadger-io/react";

export const configureHoneybadger = (apiKey) => {
  if (window.honeybadger) {
    window.honeybadger.config.apiKey = apiKey;
  }

  if (!window.honeybadger) {
    const target = process.env.REACT_APP_TARGET ?? process.env.NODE_ENV;
    const config = {
      apiKey: apiKey,
      environment: target.toLowerCase(),
      enableUncaught: true,
      developmentEnvironments: ["uat", "development", "test", "localhost"],
    };

    const honeybadger = Honeybadger.configure(config);
    honeybadger.beforeNotify((notice) => {
      if (window.location.hostname === "localhost") {
        console.log("Exception not raised as running in localhost:\n", notice);
        return false;
      }
      notice.projectRoot = "flexipay-widget";
      notice.tags = "badgers";
    });
    window.honeybadger = honeybadger;
  }
};
