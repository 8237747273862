import { FC_BORROWER_AGREEMENT_URL, FC_PRIVACY_POLICY_URL } from "utils/config";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BaseTemplate from "../BaseTemplate/BaseTemplate";
import RepaymentPills from "components/RepaymentPills/RepaymentPills";
import AppState from "state";
import { formatPrice } from "utils/priceHelpers";
import { PAGES } from "utils/config";
import "./PaymentTemplate.css";
import { Anchor } from "components";

function PaymentSummary({ paymentLink, supplierPaysFee = false }) {
  if (!paymentLink) return;

  const paymentText = () => {
    const feeText = supplierPaysFee ? "" : `plus ${paymentLink.payment_details.transaction_fee_percentage}% fee`;
    return `£${formatPrice(paymentLink.requested_amount.value)} ${feeText} in 3 instalments`;
  };
  return (
    <div className="payment-template-side-content">
      <h2 className="payment-template-header">We pay</h2>
      <span className="payment-template-supplier-name">{paymentLink.payee_account_name}</span>
      <span className="payment-template-payment-amount-bold">
        £{formatPrice(paymentLink.requested_amount.value)} now
      </span>
      <hr className="payment-template-horizontal-seperator" />

      <h2 className="payment-template-header">You pay</h2>
      <span className="payment-template-payment-amount-normal">{paymentText()}</span>
      <span className="payment-template-payment-amount-bold">
        Total: £{formatPrice(paymentLink.payment_details.total_amount.value)}
      </span>
      <RepaymentPills />
      <div className="payment-template-invoice-ref">Invoice reference: {paymentLink.reference}</div>

      <div className="payment-template-disclaimer">
        By proceeding you agree to our <Anchor text="T&CS" href={FC_BORROWER_AGREEMENT_URL} /> and{" "}
        <Anchor text="Privacy Policy" href={FC_PRIVACY_POLICY_URL} />
      </div>
    </div>
  );
}

export default function PaymentTemplate({ children, hidePaymentSummary = false, ...rest }) {
  const { backgroundClass } = rest;
  const navigate = useNavigate();
  const paymentLink = useRef(AppState.get("PaymentLink"));

  useEffect(() => {
    if (!paymentLink.current && window.location.pathname !== PAGES.errorPage) {
      navigate(PAGES.errorPage);
    }
  });

  return (
    <BaseTemplate
      expandText={`Total payment of: £${formatPrice(paymentLink.current?.payment_details.total_amount.value)}`}
      mainContent={children}
      sideContent={
        !hidePaymentSummary && (
          <PaymentSummary
            paymentLink={paymentLink.current}
            supplierPaysFee={paymentLink.current?.payment_details.supplier_pays_fee}
          />
        )
      }
      backgroundClass={backgroundClass}
    />
  );
}
