import { SplashTemplate } from "components";
import { useState, useEffect, useRef } from "react";
import "./PaymentSubmitted.css";
import ConfettiBanner from "./ConfettiBanner/ConfettiBanner";
import RepaymentPills from "components/RepaymentPills/RepaymentPills";
import AppState from "state";
import { useNavigate } from "react-router-dom";
import { PAGES } from "utils/config";
import { Anchor } from "components";
import { formatPrice } from "utils/priceHelpers";

export default function PaymentSubmitted() {
  const [counter, setCounter] = useState(10);
  const redirectUrl = sessionStorage.getItem("redirect_url");
  const paymentLink = useRef(AppState.get("PaymentLink"));
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectUrl) {
      setTimeout(() => {
        if (counter > 0) {
          setCounter((count) => count - 1);
        }

        if (counter === 1) {
          window.location.replace(redirectUrl);
        }
      }, 1000);
    }
  }, [counter, setCounter, redirectUrl]);

  function renderRedirectionBanner() {
    if (redirectUrl) {
      return (
        <div className="payment-submitted-body">
          <div className="payment-submitted-body-redirect">
            This page will automatically redirect you in <span className="font-roboto--bold">{counter} seconds</span>
          </div>
          <Anchor text="Close" href={redirectUrl} additionalClassNames={"btn btn--secondary"} />
        </div>
      );
    }
  }

  useEffect(() => {
    if (!paymentLink.current && window.location.pathname !== PAGES.errorPage) {
      navigate(PAGES.errorPage);
    }
  });

  const paymentText = () => {
    const supplierPaysFee = paymentLink.current.payment_details.supplier_pays_fee;
    const feeText = supplierPaysFee
      ? ""
      : `plus ${paymentLink.current.payment_details.transaction_fee_percentage}% fee`;

    return `£${formatPrice(paymentLink.current.requested_amount.value)}
              ${feeText} in
              ${paymentLink.current.payment_details.repayments.length} instalments`;
  };

  return (
    <>
      <ConfettiBanner />
      <SplashTemplate
        showEligibilityDisclaimer={false}
        isFlexiLite={true}
        supplierPaysFee={paymentLink.current.payment_details.supplier_pays_fee}
      >
        <div>
          <div className="fp-lite-payment-submitted-sub-container">
            <div className="fp-lite-payment-submitted-title">
              Payment to {paymentLink.current.payee_account_name} is on its way!
            </div>
            <hr className="fp-lite-payment-submitted-separator" />
            <div className="fp-lite-payment-submitted-payment-summary-title">Your payment</div>
            <div className="fp-lite-payment-submitted-payment-summary-subtitle">{paymentText()}</div>
            <RepaymentPills
              className="fp-lite-payment-submitted-repayment-pills-section"
              firstInstalmentPaid
              centred
              datesInLightMode={false}
            />
            {renderRedirectionBanner()}
          </div>
        </div>
      </SplashTemplate>
    </>
  );
}
