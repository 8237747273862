import { PaymentTemplate, Anchor } from "components";
import { ReactComponent as Icon } from "assets/images/fail-icon.svg";
import { useLocation } from "react-router-dom";
import "./ErrorPage.css";

export default function ErrorPage() {
  const location = useLocation();
  const errorState = location.state;
  const redirectUrl = sessionStorage.getItem("redirect_url");

  return (
    <PaymentTemplate hidePaymentSummary={true}>
      <div className="error-container">
        <div className="error-sub-container">
          <div className="error-icon-panel">
            <Icon width="20" height="20" viewBox="0 0 20 20" />
          </div>
          <div>
            <div className="error-title-container">
              <div className="error-title">
                {errorState?.errorTitle ? errorState.errorTitle : <>Sorry, that didn't work as expected</>}
              </div>
              {redirectUrl && <Anchor text="Close" href={redirectUrl} additionClassNames="error-redirect-link" />}
            </div>

            <div className="error-body">
              {errorState?.errorMessage ? (
                errorState.errorMessage
              ) : (
                <>
                  We can't seem to process this at the moment. Please try again and if you still experience issues, we
                  recommend coming back in a few hours.
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </PaymentTemplate>
  );
}
