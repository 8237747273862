import BaseTemplate from "../BaseTemplate/BaseTemplate";
import { ReactComponent as PieChartIcon } from "assets/images/pie-chart.svg";
import { ReactComponent as PoundCurrencyIcon } from "assets/images/pound-currency.svg";
import { ReactComponent as BarChartIcon } from "assets/images/bar-chart.svg";
import "./SplashTemplate.css";

function FlexipaySummary({ showEligibilityDisclaimer, isFlexiLite, supplierPaysFee, isLoading }) {
  const titleText = () => {
    return isFlexiLite ? "FlexiPay" : "FlexiPay Line of Credit";
  };

  const subTitleText = () => {
    if (isFlexiLite) {
      return "Pay business costs on your own terms instantly.";
    } else {
      return "Pay bills or expenses by card or transfer directly to a supplier, then repay in monthly instalments.";
    }
  };

  const benefit1Text = () => {
    const maxAmount = isFlexiLite ? "£2,000" : "£20,000";
    if (isFlexiLite) {
      const feeText = supplierPaysFee ? "with no fee" : "with a flat fee";
      return `Payments up to ${maxAmount} ${feeText} and 0% interest`;
    } else {
      return `Payments up to ${maxAmount} with a flat fee and 0% interest`;
    }
  };

  const benefit2Text = () => {
    return `You pay in 3 instalments starting ${isFlexiLite ? "today" : "next month"}`;
  };

  const benefit3Text = () => {
    if (isFlexiLite) {
      return "We pay your invoice immediately";
    } else {
      return "We pay the requested amount immediately";
    }
  };

  const BenefitItem = ({ icon, text }) => (
    <li>
      <div className="splash-template-icon-circle">{icon}</div>
      <span>{text}</span>
    </li>
  );

  return (
    <div className="splash-template-side-content">
      <h2 className="splash-template-header">
        {isLoading ? <div className="custom-payment-skeleton-loader" /> : titleText()}
      </h2>
      <span className="splash-template-description">
        {isLoading ? <div className="custom-payment-skeleton-loader" /> : subTitleText()}
      </span>
      <ul>
        {isLoading ? (
          <div className="custom-payment-skeleton-loader" />
        ) : (
          <BenefitItem icon={<PieChartIcon />} text={benefit1Text()} />
        )}
        {isLoading ? (
          <div className="custom-payment-skeleton-loader" />
        ) : (
          <BenefitItem icon={<PoundCurrencyIcon />} text={benefit2Text()} />
        )}

        {isLoading ? (
          <div className="custom-payment-skeleton-loader" />
        ) : (
          <BenefitItem icon={<BarChartIcon />} text={benefit3Text()} />
        )}
      </ul>
      {showEligibilityDisclaimer && (
        <div className="splash-template-credit-check-disclaimer">Eligibility is subject to credit assessment.</div>
      )}
    </div>
  );
}

export default function SplashTemplate({
  children,
  showEligibilityDisclaimer,
  isFlexiLite,
  supplierPaysFee,
  isLoading,
  ...rest
}) {
  const { backgroundClass } = rest;
  return (
    <BaseTemplate
      expandText="The benefits of FlexiPay?"
      mainContent={children}
      sideContent={
        <FlexipaySummary
          showEligibilityDisclaimer={showEligibilityDisclaimer}
          isFlexiLite={isFlexiLite}
          supplierPaysFee={supplierPaysFee}
          isLoading={isLoading}
        />
      }
      backgroundClass={backgroundClass}
    />
  );
}
