import "./ShareholderCheckbox.css";

export default function ShareholderCheckbox({ shareholder, handleAddDetails }) {
  const buttonText = () => {
    if (shareholder.addresses.length > 0) {
      return "Edit details";
    } else {
      return "Add details";
    }
  };

  return (
    <div className="checkbox-container">
      <div>
        <label htmlFor={shareholder.title} className="checkbox-label font-roboto--bold">
          {shareholder.title}
        </label>
        <input
          type="checkbox"
          id={shareholder.title}
          className="checkbox-input"
          disabled
          checked={shareholder.checked}
        />
        <span className="checkbox-custom"></span>
        <div>
          <label className="font-roboto-light subtitle">
            {shareholder.percent_shares_held} <span>% Ownership</span>
          </label>
        </div>
      </div>
      <button
        className="add-details-button"
        value="Add details"
        type="button"
        onClick={() => handleAddDetails(shareholder.index)}
      >
        {buttonText()}
      </button>
    </div>
  );
}
