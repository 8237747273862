import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API } from "utils/api";
import { debounce } from "utils/debounce";
import {
  MAX_PAYMENT_LINK_AMOUNT,
  MIN_PAYMENT_LINK_AMOUNT,
  PAGES,
  FLEXILITE_TITLE,
  FLEXIPAY_LINE_OF_CREDIT_TITLE
} from "utils/config";
import { REGEX } from "utils/regex";
import { Loading, SplashTemplate, DynamicLabelInput, RepaymentTerms, CurrencyInput } from "components";

import AppState from "state";
import "./UniversalPaymentSplash.css";
import { configureHoneybadger } from "utils/configureHoneybadger";
import { handleError } from "utils/handleError";
import { ReactComponent as GroupIcon } from "assets/images/Group.svg";

export default function UniversalPaymentSplash() {
  const { supplierId } = useParams();
  const [supplier, setSupplier] = useState({});
  const [payeeAmount, setPayeeAmount] = useState({ currency: "GBP", value: `${MIN_PAYMENT_LINK_AMOUNT}` });
  const [payeeReference, setPayeeReference] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const fetchSupplierData = useCallback(
    async (supplierId, requested_amount) => {
      const result = await API.fetchPublicSupplierData(supplierId, requested_amount);
      if (result.isSuccess) {
        setSupplier(result.payload);
      } else {
        handleError(result, navigate, PAGES.errorPage);
      }
      setIsLoading(false);
      setIsTyping(false);
    },
    [navigate]
  );

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    if (searchParams.get("sandbox")) {
      sessionStorage.setItem("sandbox", searchParams.get("sandbox"));
      const sandboxkey = process.env.REACT_APP_HONEYBADGER_KEY_SANDBOX;
      configureHoneybadger(sandboxkey);
    }
  }, [searchParams]);

  useEffect(() => {
    if (supplierId) {
      fetchSupplierData(supplierId, MIN_PAYMENT_LINK_AMOUNT);
    } else {
      navigate(PAGES.errorPage);
    }
  }, [supplierId, navigate, fetchSupplierData]);

  const debouncedFetchSupplierData = useMemo(
    (requested_amount) => debounce((requested_amount) => fetchSupplierData(supplierId, requested_amount), 500),
    [fetchSupplierData, supplierId]
  );

  const createSupplierPaymentLink = async () => {
    const post_payment_link_result = await API.postSupplierPaymentLink(
      supplierId,
      Number(payeeAmount.value),
      payeeReference
    );
    if (!post_payment_link_result.isSuccess) {
      throw post_payment_link_result;
    }

    const payment_link_result = await API.fetchPaymentLink(post_payment_link_result.payload.payment_link_uuid);
    if (!payment_link_result.isSuccess) {
      throw payment_link_result;
    }

    sessionStorage.setItem("payment_link_uuid", post_payment_link_result.payload.payment_link_uuid);
    AppState.updatePaymentLink(payment_link_result.payload);
  };

  // const handleSignInAction = async (event) => {
  //   event.preventDefault();
  //   setIsLoading(true);
  //   try {
  //     await createSupplierPaymentLink();
  //     navigate(PAGES.paymentConfirmation);
  //   } catch (error) {
  //     handleError(error, navigate);
  //   }
  // };

  const handleRequestedAmountChange = (event) => {
    setIsTyping(true);
    setPayeeAmount({ currency: "GBP", value: event.target.value });
    const amount = event.target.value;
    if (amount >= MIN_PAYMENT_LINK_AMOUNT) {
      debouncedFetchSupplierData(amount);
    }
  };

  const handleReferenceChange = (event) => {
    setPayeeReference(event.target.value);
  };

  function showFlexiLite() {
    return payeeAmount.value <= 2000;
  }

  const onboardingButtonText = () => {
    if (showFlexiLite()) {
      return FLEXILITE_TITLE;
    } else {
      return FLEXIPAY_LINE_OF_CREDIT_TITLE;
    }
  };

  const handleOnboardingAction = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await createSupplierPaymentLink();
      if (showFlexiLite()) {
        sessionStorage.setItem("FlexipayLiteApplication", "{}");
        navigate(PAGES.flexipayLiteEligibility);
      } else {
        navigate(PAGES.eligibility);
      }
    } catch (error) {
      handleError(error, navigate);
    }
  };

  function isValiPaymentLink() {
    const amount = Number(payeeAmount.value);
    return (
      supplier.payment_details !== undefined &&
      amount >= MIN_PAYMENT_LINK_AMOUNT &&
      amount <= MAX_PAYMENT_LINK_AMOUNT &&
      payeeReference.match(REGEX.paymentReference)
    );
  }

  function renderBody() {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="universal-payment-splash">
        <div className="universal-payment-splash-group-icon">
          <GroupIcon />
        </div>
        <div className="universal-payment-splash-header">{supplier.supplier_name}</div>
        <div className="universal-payment-splash-subheading">Requested a payment from you</div>
        <div className="universal-payment-splash-form">
          <div className="universal-payment-splash-amount-input">
            <CurrencyInput
              id="currency-input"
              text="Amount to pay"
              enableValidation={false}
              value={payeeAmount.value}
              maxValue={MAX_PAYMENT_LINK_AMOUNT}
              amountChangedCallback={handleRequestedAmountChange}
            />
          </div>
          <DynamicLabelInput
            id="payment-reference"
            text="Invoice reference"
            value={payeeReference}
            regexRules={REGEX.paymentReference}
            onChange={handleReferenceChange}
            autoComplete="off"
          />
        </div>
        <RepaymentTerms paymentDetails={supplier.payment_details} isFlexiLite={showFlexiLite()} />
        <input
          className="universal-payment-splash-newbo-button btn btn--primary"
          id="Continue"
          type="submit"
          value={onboardingButtonText()}
          onClick={handleOnboardingAction}
          disabled={!isValiPaymentLink() || isTyping}
        />

        {/* <span className={`universal-payment-splash-exbo-sign-in ${!isValiPaymentLink() ? "hidden" : ""}`}>
          Already got FlexiPay?
          <input
            className="universal-payment-splash-exbo-button button-link"
            type="submit"
            value="Sign in to make this payment"
            disabled={!isValiPaymentLink() || isTyping}
            onClick={handleSignInAction}
          />
        </span> */}
      </div>
    );
  }

  return (
    <SplashTemplate
      isFlexiLite={showFlexiLite()}
      showEligibilityDisclaimer={true}
      isLoading={isLoading}
      supplierPaysFee={supplier.payment_details?.supplier_pays_fee}
    >
      {renderBody()}
    </SplashTemplate>
  );
}
