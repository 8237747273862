const DEFAULT_MAIN_APP_STATE_KEY = "flexipay-request-payment-app";

export default class AppState {
  // Public Functions
  static get = (key) => (JSON.parse(sessionStorage.getItem(DEFAULT_MAIN_APP_STATE_KEY)) || {})[key];
  static updatePaymentLink = (paymentLink) => this._replaceState("PaymentLink", paymentLink);
  static updateEligibility = (eligibility) => this._replaceState("Eligibility", eligibility);
  static updateLoanApplication = (key, value) => this._updateState("LoanApplication", { [key]: value });
  static resetLoanApplication = () => this._replaceState("LoanApplication", {});
  static updateFlexipayLiteApplication = (key, value) => this._updateState("FlexipayLiteApplication", { [key]: value });
  static resetFlexipayLiteApplication = () => this._replaceState("FlexipayLiteApplication", {});

  // Private Functions
  static _replaceState(key, value) {
    const mainAppState = JSON.parse(sessionStorage.getItem(DEFAULT_MAIN_APP_STATE_KEY)) || {};
    mainAppState[key] = value;
    sessionStorage.setItem(DEFAULT_MAIN_APP_STATE_KEY, JSON.stringify(mainAppState));
  }

  static _updateState(key, value) {
    const mainAppState = JSON.parse(sessionStorage.getItem(DEFAULT_MAIN_APP_STATE_KEY)) || {};
    mainAppState[key] = Object.assign({}, mainAppState[key], value);
    sessionStorage.setItem(DEFAULT_MAIN_APP_STATE_KEY, JSON.stringify(mainAppState));
  }
}
