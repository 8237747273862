import React from "react";
import "./ShareholderInput.css";
import { Label } from "components";
import { REGEX } from "utils/regex";
import { nthNumber } from "utils/numberHelpers";
import { DynamicLabelInput } from "components";

export default function ShareholderInput({ shareholder, index, updateShareholders, isLast }) {
  // Has to be useState instead of useRef because the component needs to re-render when the input changes, otherwise it breaks the validation
  const ordinalNumber = (index + 1).toString() + nthNumber(index + 1);

  const validateShareholder = (shareholder) => {
    const propsToValidate = [shareholder.first_name, shareholder.last_name];
    return propsToValidate.every((value) => isValidName(value));
  };

  const isValidName = (name) => {
    if (name === "") {
      return false;
    }
    const regex = new RegExp(REGEX.name);
    const valid = regex.test(name);
    return valid;
  };

  const handleEditShareholder = (e) => {
    const key = e.target.name;
    const newShareholder = { ...shareholder, [key]: e.target.value };
    updateShareholders({ ...newShareholder, valid: validateShareholder(newShareholder) }, index, "edit");
  };

  const handleAddShareholder = () => {
    const placeholder = { first_name: "", last_name: "", valid: false };
    updateShareholders(placeholder, index, "add");
  };

  const handleRemoveShareholder = () => {
    updateShareholders(null, index, "remove");
  };

  return (
    <li className="shareholder-input-container">
      <div className="shareholder-input-header">
        <Label
          text={[
            index + 1,
            <sup key={ordinalNumber + "-shareholder-sup-number"}>{nthNumber(index + 1)}</sup>,
            " person",
          ]}
        />
        {!(isLast && index === 0) && (
          <label
            className="shareholder-input-action-button"
            value="Add details"
            type="button"
            onClick={handleRemoveShareholder}
          >
            × Remove
          </label>
        )}
      </div>
      <div className="shareholder-input-row">
        <DynamicLabelInput
          id={ordinalNumber + "-shareholder-first-name"}
          name="first_name"
          text={"First name"}
          value={shareholder.first_name ? shareholder.first_name : ""}
          onChange={(e) => handleEditShareholder(e)}
          regexRules={REGEX.name}
          maxLength={45}
          errorMessage={shareholder.first_name === "" ? "First name is required" : "Invalid first name value"}
        ></DynamicLabelInput>
        <DynamicLabelInput
          id={ordinalNumber + "-shareholder-last-name"}
          name="last_name"
          text={"Last name"}
          value={shareholder.last_name ? shareholder.last_name : ""}
          onChange={(e) => handleEditShareholder(e)}
          regexRules={REGEX.name}
          maxLength={45}
          errorMessage={shareholder.first_name === "" ? "Last name is required" : "Invalid last name value"}
        ></DynamicLabelInput>
      </div>
      {isLast && index < 9 && (
        <div className="shareholder-input-header">
          <label className="label-title font-roboto-normal">
            {index + 2}
            <sup>{nthNumber(index + 2)} </sup>person
          </label>
          <label className="shareholder-input-action-button" type="button" onClick={handleAddShareholder}>
            + Add person
          </label>
        </div>
      )}
    </li>
  );
}
