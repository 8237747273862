import "./AddressHistory.css";

export default function AddressHistory({ shareholderName, shareholderAddresses, handleEditAddress, handleAddAddress }) {
  return (
    <div>
      <div className="title-section">
        <p className="label-title-dark">
          Please add 3 years of address for: <span className="font-roboto--bold">{shareholderName}</span>
        </p>
      </div>
      {buildAddressesComponents(shareholderAddresses, handleEditAddress)}
      {buildPreviousAddressComponent(handleAddAddress)}
    </div>
  );
}

function buildAddressesComponents(addresses, handleEditAddress) {
  const stringifyAddress = (address) => {
    return `${address.houseNameNumber} ${address.street}, ${address.postcode}`;
  };

  return addresses.map((address, index) => {
    const title = index === 0 ? "Current address:" : "Previous address:";

    return (
      <div key={`address-${index}`}>
        <div className="address-section">
          <div>
            <div className="address-label-title font-roboto-normal">{title}</div>
            <span className="address-title font-roboto--bold">{stringifyAddress(address)}</span>
            &nbsp;
            {`(Since ${address.year})`}
          </div>
          <button className="address-button" value="Edit" type="button" onClick={() => handleEditAddress(index)}>
            Edit
          </button>
        </div>
        <div className="address-separator" />
      </div>
    );
  });
}

function buildPreviousAddressComponent(handleAddAddress) {
  return (
    <div key="previous-address">
      <div className="address-section">
        <div className="address-label-title font-roboto-normal">Previous address</div>
        <button
          className="address-button"
          value="Add"
          type="button"
          onClick={() => {
            handleAddAddress();
          }}
        >
          Add
        </button>
      </div>
      <div className="address-separator" />
    </div>
  );
}
