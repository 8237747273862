import { useState } from "react";
import { PaymentTemplate, PageNumberTitle, DynamicLabelInput } from "components";
import AppState from "state";
import { useNavigate } from "react-router-dom";
import { PAGES } from "utils/config";
import "./Directors.css";

export default function Directors() {
  const navigate = useNavigate();
  const nameValidations = /^[a-zA-Z]{3,20}$/;
  const [director, setDirector] = useState({
    firstName: "",
    lastName: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    AppState.updateLoanApplication("director_first_name", director.firstName);
    AppState.updateLoanApplication("director_last_name", director.lastName);
    navigate(PAGES.shareholders);
  };

  const disableButton = () => {
    return director.firstName.length < 3 || director.lastName.length < 3;
  };

  return (
    <PaymentTemplate>
      <PageNumberTitle title="Tell us who you are" number={2} />
      <form id="director-form" onSubmit={handleSubmit}>
        <div className="directors-content">
          <DynamicLabelInput
            text="First Name"
            id="first-name"
            onChange={(e) => {
              setDirector({ ...director, firstName: e.target.value });
            }}
            value={director.firstName}
            regexRules={nameValidations}
          />
          <DynamicLabelInput
            text="Last Name"
            id="last-name"
            onChange={(e) => {
              setDirector({ ...director, lastName: e.target.value });
            }}
            value={director.lastName}
            regexRules={nameValidations}
          />
          <input className="btn btn--primary" type="submit" value="Continue" disabled={disableButton()} />
        </div>
      </form>
    </PaymentTemplate>
  );
}
