import "./index.css";
import ReactDOM from "react-dom/client";
import React from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { configureHoneybadger } from "utils/configureHoneybadger";
import { PAGES } from "utils/config";
import Hotjar from "@hotjar/browser";
// import { PrivateRoute } from "components";

// Common flow
import { CustomPaymentSplash } from "pages/Supplier";
import { UniversalPaymentSplash } from "pages/Supplier";
// import SignIn from "pages/Shared/Authentication/SignIn/SignIn";
import { ErrorPage } from "pages/Shared/ErrorPage";

// FP onboarding flow
import Eligibility from "pages/Onboarding/Eligibility/Eligibility";
import EligibilityPolling from "pages/Onboarding/EligibilityPolling/EligibilityPolling";
import LoanApplicationPolling from "pages/Onboarding/LoanApplicationPolling/LoanApplicationPolling";
import Directors from "pages/Onboarding/Directors/Directors";
import Shareholders from "pages/Onboarding/Shareholders/Shareholders";
import BusinessDetails from "pages/Onboarding/BusinessDetails/BusinessDetails";
import DirectDebit from "pages/Onboarding/DirectDebit/DirectDebit";
// import PaymentConfirmation from "pages/Shared/PaymentConfirmation/PaymentConfirmation";
import { default as FlexipayPaymentSubmitted } from "pages/Shared/PaymentSubmitted/PaymentSubmitted";
// import LoginRequired from "pages/Onboarding/LoginRequired/LoginRequired";
import Rejection from "pages/Onboarding/Rejection/Rejection";

// FP lite flow
import { default as FlexipayLiteEligibility } from "pages/FlexipayLite/Eligibility/Eligibility";
import { default as FlexipayLiteShareholders } from "pages/FlexipayLite/Shareholders/Shareholders";
import { default as FlexipayLitePaymentSubmitted } from "pages/FlexipayLite/PaymentSubmitted/PaymentSubmitted";
import StripeSubscriptionPayment from "pages/FlexipayLite/StripeSubscriptionPayment/StripeSubscriptionPayment";

ReactDOM.createRoot(document.getElementById("root")).render(<App />);

if (process.env.REACT_APP_TARGET) {
  const siteId = 1328580;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}

function App() {
  configureHoneybadger(process.env.REACT_APP_HONEYBADGER_KEY_LIVE);

  return (
    <CookiesProvider>
      <Router>
        <Routes>
          {/* Common flow */}
          <Route path={PAGES.errorPage} element={<ErrorPage />} />
          {/* <Route path={PAGES.signIn} element={<SignIn />} /> */}
          <Route path={PAGES.customPaymentSplash} element={<CustomPaymentSplash />} />
          <Route path={PAGES.universalPaymentSplash} element={<UniversalPaymentSplash />} />
          {/* FP onboarding flow */}
          <Route path={PAGES.eligibility} element={<Eligibility />} />
          <Route path={PAGES.eligibilityPolling} element={<EligibilityPolling />} />
          <Route path={PAGES.directors} element={<Directors />} />
          <Route path={PAGES.shareholders} element={<Shareholders />} />
          <Route path={PAGES.businessDetails} element={<BusinessDetails />} />
          <Route path={PAGES.loanApplicationPolling} element={<LoanApplicationPolling />} />
          <Route path={PAGES.directDebit} element={<DirectDebit />} />
          {/* <Route path={PAGES.paymentConfirmation} element={<PrivateRoute Component={PaymentConfirmation} />} /> */}
          <Route path={PAGES.paymentSubmitted} element={<FlexipayPaymentSubmitted />} />
          {/* <Route path={PAGES.loginRequired} element={<LoginRequired />} /> */}
          <Route path={PAGES.rejection} element={<Rejection />} />
          {/* FP Lite flow */}
          <Route path={PAGES.flexipayLiteEligibility} element={<FlexipayLiteEligibility />} />
          <Route path={PAGES.flexipayLiteShareholders} element={<FlexipayLiteShareholders />} />
          <Route path={PAGES.flexipayLitePayment} element={<StripeSubscriptionPayment />} />
          <Route path={PAGES.flexipayLitePaymentSubmitted} element={<FlexipayLitePaymentSubmitted />} />
        </Routes>
      </Router>
    </CookiesProvider>
  );
}
