import Select from "react-select";

export const daysSelectBox = (
  onChange,
  initialValue = undefined,
  selectedMonth = undefined,
  selectedYear = undefined,
  menuPlacement = "auto"
) => {
  const now = new Date();
  const month = !!selectedMonth ? selectedMonth + 1 : 1;
  const year = !!selectedYear ? selectedYear : now.getFullYear();
  const daysInThisMonth = new Date(year, month, 0).getDate();
  const days = Array.from(Array(daysInThisMonth)).map((_, i) => i + 1);
  return createSelectElement("days", days, "DD", onChange, initialValue, menuPlacement);
};

const months = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

const convertMonthValueToMonthName = (month) => {
  let monthValue = undefined;
  for (const [key, value] of Object.entries(months)) {
    if (value === month) {
      monthValue = key;
      break;
    }
  }
  return monthValue;
};

export const monthsSelectBox = (onChange, initialValue = undefined, menuPlacement = "auto") => {
  if (initialValue !== undefined) {
    initialValue = convertMonthValueToMonthName(initialValue);
  }
  return createSelectElement("months", Object.keys(months), "MM", onChange, initialValue, menuPlacement);
};

export const convertMonthNameToMonthValue = (monthName) => {
  return months[monthName];
};

export const yearsSelectBox = (onChange, initialValue = undefined, menuPlacement = "auto") => {
  const now = new Date();
  let startYear = now.getFullYear() - 100;
  const currentYear = new Date().getFullYear();
  let years = [];
  while (startYear <= currentYear) {
    years.push(startYear++);
  }

  return createSelectElement("years", years.reverse(), "YYYY", onChange, initialValue, menuPlacement);
};

const customStyles = {
  dropdownIndicator: (base) => ({
    ...base,
    color: "#0079B3",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "48px",
    boxShadow: state.isFocused ? null : null,
  }),
};

export const createSelectElement = (
  id,
  items,
  placeholder,
  onChange,
  initialValue = undefined,
  menuPlacement = "auto"
) => {
  const options = items.map((value) => {
    return {
      id: id,
      value: value,
      label: value,
    };
  });

  let defaultValue = undefined;
  if (initialValue !== undefined) {
    defaultValue = { id: id, label: initialValue, value: initialValue };
  }

  return (
    <Select
      onChange={onChange}
      isSearchable={false}
      menuPlacement={menuPlacement}
      maxMenuHeight={200}
      styles={customStyles}
      placeholder={placeholder}
      label={id}
      defaultValue={defaultValue}
      className={`select-dropdown ${id}`}
      options={options}
    />
  );
};
