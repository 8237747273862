import "./SearchInput.css";

import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import { ReactComponent as CloseIcon } from "assets/images/close-icon.svg";

import { DynamicLabelInput } from "components";

export default function SearchInput({
  id,
  value,
  placeholderText,
  readOnly,
  onChange,
  onClosePress,
  selectedItem,
  isListResultOpen,
}) {
  return (
    <>
      <DynamicLabelInput
        type="text"
        text={placeholderText}
        name={id}
        id={id}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        hasRightSideIcon={true}
      />
      {!!Object.keys(selectedItem).length || isListResultOpen ? (
        <CloseIcon
          data-testid="close-icon"
          className="search-input-close-icon"
          onClick={onClosePress}
          width="20"
          height="20"
          viewBox="0 0 24 24"
        />
      ) : (
        <SearchIcon
          data-testid="search-icon"
          className="search-input-icon"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        />
      )}
    </>
  );
}
