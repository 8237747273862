import { daysSelectBox, monthsSelectBox, yearsSelectBox, convertMonthNameToMonthValue } from "./DOBUtils";
import { useState } from "react";

import { ReactComponent as SuccessIcon } from "assets/images/success-icon.svg";
import { ReactComponent as FailIcon } from "assets/images/fail-icon.svg";

import "./DOBSelector.css";

export default function DOBSelector({ dob, name, handleDOBSubmitted }) {
  const [day, setDay] = useState(dob.day);
  const [month, setMonth] = useState(dob.month);
  const [year, setYear] = useState(dob.year);

  const submitDOB = () => {
    handleDOBSubmitted(day, month, year);
  };

  const isValidDate = () => {
    const date = new Date(year, month, day);
    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  };

  const showError = () => {
    return !!(day && month && year && !isValidDate());
  };

  return (
    <div className="dob-container">
      <div className="dob-title-container">
        <p className="label-title-dark font-roboto-normal">
          Update the date of birth for shareholder: <span className="font-roboto--bold">{name}</span>
        </p>
        <div className="dob-icon-container">
          {isValidDate() && <SuccessIcon />}
          {showError() && <FailIcon />}
        </div>
      </div>

      <div className="select-container">
        {daysSelectBox((e) => setDay(e.value), day, month, year)}
        {monthsSelectBox((e) => setMonth(convertMonthNameToMonthValue(e.value)), month)}
        {yearsSelectBox((e) => setYear(e.value), year)}
      </div>
      <div className="button-container">
        <input
          className="btn btn--primary"
          type="submit"
          id="Next: Add address history"
          value="Next: Add address history"
          disabled={!isValidDate()}
          onClick={submitDOB}
        />
      </div>
    </div>
  );
}
