import "./StripeSubscriptionPayment.css";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { PaymentTemplate, Loading, PageNumberTitle } from "components";
import { useNavigate } from "react-router-dom";
import { PAGES } from "utils/config";
import AppState from "state";

export const StripePaymentForm = ({ submitCallback }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const paymentElementOptions = {
    business: {
      name: "Funding Circle",
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    submitCallback(true);

    const return_url = `${window.location.origin}${PAGES.flexipayLitePaymentSubmitted}`;
    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: return_url, // Some payment providers redirect to do auth checks before making the payment, this return_url is where we can redirect them to the payment confirmation screen if that happens
      },
      redirect: "if_required",
    });
    if (paymentIntent && paymentIntent.status === "succeeded") {
      navigate(PAGES.flexipayLitePaymentSubmitted);
    } else {
      console.log("Payment failed");
      if (error) console.log(error);
      submitCallback(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="stripe-subscription-payment-debit-card-disclaimer">Only debit card payments are accepted.</p>
      <div className="stripe-subscription-payment-container">
        <div className="stripe-subscription-payment-form">
          <PaymentElement options={paymentElementOptions} onReady={() => setIsLoading(false)} />
        </div>
        {<input className="btn btn--primary" type="submit" value="Continue" disabled={isLoading} />}
      </div>
    </form>
  );
};

export default function StripeSubscriptionPayment() {
  const navigate = useNavigate();
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const [stripePromise] = useState(() => loadStripe(publicKey));
  const appearance = {
    theme: "flat",
    labels: "floating",
    variables: {
      fontFamily: "Roboto, sans-serif",
      borderRadius: "4px",
      colorDanger: "#ce1025",
      iconCardCvcErrorColor: "#ce1025",
      iconCardErrorColor: "#ce1025",
      spacingUnit: "3px",
      gridRowSpacing: "24px",
      gridColumnSpacing: "16px",
    },
    rules: {
      ".Input": {
        backgroundColor: "white",
        border: "1px solid #737480",
        fontFamily: "Roboto, sans-serif",
        color: "#424554",
        fontWeight: "bolder",
        paddingTop: "8px",
        paddingBottom: "12px",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      ".Input::placeholder": {
        fontWeight: "normal",
      },
      ".Input--invalid": {
        color: "#424554",
        boxShadow: "0 0 0 1px #ce1025",
      },
      ".Error": {
        fontSize: "12px",
        paddingTop: "3px",
        marginTop: "3px",
        color: "#ce1025",
        fontFamily: "Roboto, sans-serif",
      },
      ".Label--floating": {
        paddingTop: "0px",
        paddingBottom: "0px",
        color: "black",
        fontFamily: "Roboto, sans-serif",
        fontSize: "12px",
        margin: "-5px",
      },
    },
  };

  const clientSecret =
    AppState.get("FlexipayLiteApplication") && AppState.get("FlexipayLiteApplication")["stripe_client_secret"];
  // if we have only one shareholder, means we never visited the shareholders page,
  // so we should not display 3 steps in the navigation
  const pageNumber =
    AppState.get("FlexipayLiteApplication") && AppState.get("FlexipayLiteApplication")["shareholders"].length > 1
      ? 3
      : 2;

  const submitCallback = (isLoading) => {
    setIsStripeLoading(isLoading);
  };

  useEffect(() => {
    if (!AppState.get("FlexipayLiteApplication")) {
      navigate(PAGES.errorPage);
    }
  });

  return (
    <PaymentTemplate>
      <PageNumberTitle title="Your Card Details" number={pageNumber} />
      {isStripeLoading && (
        <div className="stripe-subscription-payment-loading">
          <Loading />
        </div>
      )}
      <div className={isStripeLoading ? "stripe-subscription-payment-element" : ""}>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
            loader: "always",
            appearance,
          }}
        >
          <StripePaymentForm submitCallback={submitCallback} />
        </Elements>
      </div>
    </PaymentTemplate>
  );
}
