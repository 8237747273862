import { DynamicLabelInput } from "components";

export default function CurrencyInput({
  id,
  text,
  value,
  regexRules,
  amountChangedCallback,
  enableValidation = true,
  maxValue,
}) {
  const stripText = (value) => {
    let stripped = value.replace(/^0+/g, ""); // No leading zeroes
    stripped = stripped.replace(/^\.+/g, ""); // No leading decimal points
    stripped = stripped.replace(/[^0-9.]/g, ""); // Only allow digits and decimal points
    stripped = stripped.replace(/(?<=\.\d{2}).+/g, ""); // Only allow max of two digits after a decimal point
    stripped = stripped.replace(/(?<=\.)\.+/g, ""); // No more than one decimal point

    if (maxValue && Number(stripped) >= maxValue) {
      return maxValue;
    }

    return stripped;
  };

  const currencyValidations = /^[1-9][\d.]*$/;
  const handleChange = (event) => {
    const text = event.target.value;
    const stripped = stripText(text);
    event.target.value = stripped;

    amountChangedCallback(event);
  };

  return (
    <DynamicLabelInput
      id={id}
      text={text}
      sideLabel="£"
      value={value ?? ""}
      maxLength={10}
      regexRules={enableValidation && (regexRules || currencyValidations)}
      onChange={handleChange}
    />
  );
}
