import { useState } from "react";
import { ReactComponent as Icon } from "assets/images/info-error-icon.svg";
import "./DynamicLabelInput.css";

export default function DynamicLabelInput({
  id,
  name = id,
  text,
  value,
  sideLabel,
  onChange,
  regexRules,
  readOnly,
  maxLength,
  hasRightSideIcon,
  errorMessage = `Valid ${text.toLowerCase()} is required`,
  infoMessage,
  autoComplete = "off",
  numbersOnly,
  ...rest
}) {
  const [error, setError] = useState();
  const regex = new RegExp(regexRules);

  const validateField = (event) => {
    if (!regexRules) return;

    const input = event.target.value;
    const valid = regex.test(input);
    setError(!valid);
  };

  const handleChange = (event) => {
    const text = event.target.value;
    const valid = regex.test(text);

    if (numbersOnly) {
      const stripped_text = text.replace(/[^0-9]/g, "");
      event.target.value = stripped_text;
    }
    if (valid && regexRules) setError(false);
    onChange(event);
  };

  return (
    <div className="dynamic-label-input">
      {sideLabel && <div className="side-label">{sideLabel}</div>}
      <div className="dynamic-label-input-main">
        <input
          type="text"
          name={name}
          id={id}
          data-testid={id}
          value={value}
          maxLength={maxLength}
          readOnly={readOnly}
          className={`${error ? "error" : ""} ${hasRightSideIcon ? "dynamic-label-input-icon-padding" : ""}`}
          onChange={handleChange}
          onBlur={validateField}
          aria-autocomplete="none"
          autoComplete={autoComplete}
          {...rest}
        />
        <label className={value || value === 0 ? " small" : null} htmlFor={id}>
          {text}
        </label>
      </div>
      {error && (
        <div className="dynamic-label-input-error">
          <Icon className="dynamic-label-input-error-icon" width="16" height="16" />
          <div>{errorMessage}</div>
        </div>
      )}
      {!error && infoMessage && (
        <div className="dynamic-label-input-info-message">
          <Icon className="dynamic-label-input-message-icon" width="16" height="16" />
          <div>{infoMessage}</div>
        </div>
      )}
    </div>
  );
}
