import { DynamicLabelInput, Loading, PaymentTemplate, PageNumberTitle, Anchor, StickyButton } from "components";
import "./DirectDebit.css";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Icon } from "assets/images/direct-debit.svg";
import { PAGES } from "utils/config";
import { API } from "utils/api";
import AppState from "state";
import { handleError } from "utils/handleError";

import { MODULR_URL, DIRECT_DEBIT_GUARANTEE_URL, FLEXIPAY_AGREEMENT_URL } from "utils/config";

export default function DirectDebit() {
  const navigate = useNavigate();
  const nameValidations = /^[a-zA-Z' ]+$/;
  const accountNumberValidation = /(\d){8,8}$/;
  const sortCodeValidation = /^[0-9]{6}$/;
  const creditLimit = useRef(AppState.get("LoanApplication")["credit_limit"]);
  const loanApplicationUuid = useRef(AppState.get("LoanApplication")["loan_application_uuid"]);
  const [isLoading, setIsLoading] = useState(false);
  const [payeeData, setPayeeData] = useState({ name: "", number: "", sort_code: "" });

  async function callPostLineOfCredit(payeeData) {
    setIsLoading(true);
    const result = await API.postLineOfCredit(loanApplicationUuid.current, payeeData);

    if (result.isSuccess) {
      navigate(PAGES.paymentSubmitted);
    } else {
      handleError(result, navigate);
    }
    setIsLoading(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    callPostLineOfCredit(payeeData);
  };

  const disableButton = () => {
    return payeeData.name.length < 3 || payeeData.number.length < 8 || payeeData.sort_code.length < 6;
  };

  function renderBody() {
    if (isLoading) {
      return (
        <div className="direct-debit-loading-container">
          <Loading />
        </div>
      );
    }

    return (
      <form id="container" onSubmit={handleSubmit}>
        <div className="direct-debit-content">
          <label className="direct-debit-sub-title">
            We will set up a direct debit to make your 3 monthly payments.
          </label>
          <div className="direct-debit-description-top-section">
            <span className="direct-debit-description-top-section-main" data-testid="credit-limit-description">
              You have been approved for a line of credit of £ {creditLimit.current}.{" "}
            </span>
            <span className="direct-debit-description-top-section-secondary">
              If you choose to continue, this line of credit will be set up, and this payment will be automatically
              submitted from this limit.
            </span>
          </div>
          <DynamicLabelInput
            text="Business account name"
            id="business-account-name"
            onChange={(e) => {
              setPayeeData({ ...payeeData, name: e.target.value });
            }}
            value={payeeData.name}
            regexRules={nameValidations}
          />
          <div className="direct-debit-horizontal-grouping">
            <DynamicLabelInput
              text="Account number"
              id="account-number"
              maxLength={8}
              onChange={(e) => {
                setPayeeData({ ...payeeData, number: e.target.value });
              }}
              value={payeeData.number}
              regexRules={accountNumberValidation}
              numbersOnly={true}
            />
            <DynamicLabelInput
              text="Sort code"
              id="sort-code"
              maxLength={6}
              onChange={(e) => {
                setPayeeData({ ...payeeData, sort_code: e.target.value });
              }}
              value={payeeData.sort_code}
              regexRules={sortCodeValidation}
              numbersOnly={true}
            />
          </div>
          <div>
            Upon activation of your line of credit, we will complete a full credit search on yourself and the business.
            <br />
            <br />
            <div>
              By clicking below you confirm you have read and accept the{" "}
              <Anchor text="Modulr terms and conditions" href={MODULR_URL} /> and your{" "}
              <span className="direct-debit-description-link">
                <Anchor
                  text="FlexiPay credit agreement"
                  href={FLEXIPAY_AGREEMENT_URL(loanApplicationUuid.current)}
                  additionClassNames="error-redirect-link"
                />{" "}
              </span>
              and authorise Funding Circle as a user of your Modulr account.
            </div>
          </div>
          <div className="direct-debit-guarantee-section">
            <div>
              Your payments are protected by{" "}
              <Anchor
                text="Direct Debit Guarantee"
                href={DIRECT_DEBIT_GUARANTEE_URL}
                additionalClassNames="direct-debit-description-link"
              />
            </div>
            <div>
              <Icon />
            </div>
          </div>
        </div>
        <StickyButton text="Accept your FlexiPay offer and submit payment" disabled={disableButton()} />
      </form>
    );
  }

  return (
    <PaymentTemplate>
      <PageNumberTitle title="Set up your direct debit" number={5} />
      {renderBody()}
    </PaymentTemplate>
  );
}
