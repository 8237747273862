import "./RepaymentTerms.css";
import { formatPrice } from "utils/priceHelpers";

export default function RepaymentTerms({ paymentDetails }) {
  if (paymentDetails === undefined) {
    return null;
  }

  const paymentText = () => {
    const feeText = paymentDetails.supplier_pays_fee
      ? ""
      : `includes ${paymentDetails.transaction_fee_percentage}% fee`;
    return `£${formatPrice(paymentDetails.total_amount.value)} in total ${feeText}`;
  };
  return (
    <div className="repayment-terms-description">
      <span>{paymentText()}</span>
    </div>
  );
}
