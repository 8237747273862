import "./PageNumberTitle.css";

export default function PageNumberTitle({ number = undefined, title }) {
  return (
    <div className="page-number-title-container">
      {number && <div className="page-number-title-circle">{number}</div>}
      <div className="page-number-title-text">{title}</div>
    </div>
  );
}
